import React, { useState, useEffect, useRef } from 'react';
import { FixedSizeList as List } from 'react-window';
import AutoSizer from 'react-virtualized-auto-sizer';

import './dropdownsearch.css';
import Regex from '../../../util/regex';

const rowHeight = 35;

function useOutsideAlerter(
  ref: React.RefObject<HTMLDivElement>,
  cb?: Function,
) {
  useEffect(() => {
    /**
     * Alert if clicked on outside of element
     */
    function handleClickOutside(event: any) {
      if (ref.current && !ref.current.contains(event.target) && cb) {
        cb();
      }
    }

    // Bind the event listener
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [ref, cb]);
}

const DropDownSearch = (props: any) => {
  const [selectionContainerVisible, setSelectionContainerVisible] =
    useState<boolean>(false);
  const [list, setList] = useState<Array<string>>([]);
  const [value, setValue] = useState<string>('');

  useEffect(() => {
    const initialize = () => {
      setList(props.options);
      if (props.selected) {
        setValue(props.selected);
      }
    };
    initialize();
  }, [props]);
  const wrapperRef = useRef(null);
  useOutsideAlerter(wrapperRef, () => setSelectionContainerVisible(false));

  function select(index: number) {
    if (props.onSelect) props.onSelect(list[index]);
    setSelectionContainerVisible(false);
    setValue(list[index]);
  }

  function renderRow({ index, key, style }: any) {
    return (
      <div
        className="info-page-suggestion-option cursor-pointer"
        key={key}
        style={style}
        onClick={() => select(index)}
      >
        {list[index]}
      </div>
    );
  }

  function renderListNormal() {
    return list.map((value: any, index: number) => {
      return (
        <div
          className="info-page-suggestion-option cursor-pointer hover:bg-gray-100"
          key={index}
          onClick={() => select(index)}
        >
          {value}
        </div>
      );
    });
  }

  function updateList(keyword: string) {
    const newList = props.options.filter((elem: any) => {
      if (!Regex.keywordMatch(elem, keyword)) return false;
      return true;
    });
    setValue(keyword);
    setList(newList);
  }

  function onChange(e: any) {
    const keyword = e.target.value;
    updateList(keyword);
  }

  return (
    <div className="classic-search-dropdown-container">
      <div className="classic-search-dropdown-label">
        {props.label}
        {props.required ? (
          <span className="classic-search-dropdown-label-required"> *</span>
        ) : null}
      </div>
      <input
        className="classic-search-dropdown-box"
        placeholder="Search location"
        value={value}
        onFocus={(e) => {
          setSelectionContainerVisible(true);
          updateList(e.target.value);
        }}
        onChange={onChange}
      />
      {selectionContainerVisible ? (
        <div
          className="classic-search-dropdown-suggestion-container"
          ref={wrapperRef}
        >
          {props.virtualized ? (
            <AutoSizer disableWidth disableHeight>
              {({ height }: any) => (
                <List
                  width={1}
                  height={200}
                  itemSize={rowHeight}
                  itemCount={list.length}
                  style={{
                    width: '100%',
                    maxWidth: '100%',
                  }}
                >
                  {({ style, index }) => (
                    <div
                      className="info-page-suggestion-option cursor-pointer"
                      style={style}
                      onClick={() => select(index)}
                    >
                      {list[index]}
                    </div>
                  )}
                </List>
              )}
            </AutoSizer>
          ) : (
            renderListNormal()
          )}
        </div>
      ) : null}
    </div>
  );
};

export default DropDownSearch;
