import moment from 'moment';
import LocalizedStrings from 'react-localization';

moment.locale(navigator.language);

export let stringsPricingPage = new LocalizedStrings({
  en: {
    // Motivation page
    advanceMyCareer: 'Advance my career',
    confidenceInSocialSetting: 'Confidence in social settings',
    expandMyKnowledge: 'Expand my knowledge',
    other: 'Other',
    welcomeToImmigo: `Let's get to know you better!`,
    tellUsALittleBitAboutYourself:
      'Please tell us a little bit about yourself so that we can find you the right learning plan and sessions for you.',
    whyWorkingOnEnglish: 'Why are you working on your English speaking skill?',

    // Level page
    yourEnglishJourney: 'Your English journey',
    tellUsYourEnglishLevel:
      'Tell us your English level to better customize your learning experience.',
    beginner: 'Beginner',
    elementary: 'Elementary',
    preIntermediate: 'Pre-intermediate',
    intermediate: 'Intermediate',
    upperIntermediate: 'Upper intermediate',
    advanced: 'Advanced',
    levelNotFit:
      'Our classes are designed for learners who are intermediate and above.',

    // Level page waitlist modal
    a1ClassesComingSoon: 'A1 (Beginner) classes coming soon!',
    a1ComingSoonLeaveDetail:
      'We are planning to launch our A1 (Beginner) classes soon. Leave your contact information here if you want us to reach back out to you when they are open!',

    // Nationality page
    whatIsYourNationality: 'What is your nationality?',
    learnersFrom120Countries:
      'Our learners are from 120+ different countries. Knowing where you are from helps us better understand your English learning needs.',
    letsFindYourCountry: `Let's find your country!`,
    chooseYourCountryPressSubmit:
      'Please choose your country and press "Submit"',
    submit: 'Submit',

    // Based Country page
    whichCountryDoYouLiveIn: 'Which country do you live in?',
    manyAreImmigrants:
      'Tell us where you are from, so that we can find you sessions that better fit your needs.',

    // Program page
    accelarationProgram: 'Acceleration Program',
    next6Months:
      'Over the next 6 months, we will guide you through a learning path designed according to the CEFR Framework, helping you advance to the next level.',
    advancedEnglishExpressions: 'Advanced English Expressions',
    pronunciation: 'Pronunciation',
    communicationAndPublicSpeaking: 'Communication & Public speaking',
    vocabulary: 'Vocabulary',
    grammar: 'Grammar',

    //Loading
    findingRightPlanForYou: 'Finding the right plan for you...',
    loadingCustomizedClasses: 'Loading your customized classes...',

    // Vision page
    envisionYourSelf: 'Envision your future progress',
    basedOnWhatYouToldJourney:
      "Based on what you told us, here's what your journey will look like at Immigo.",
    scrollDownToSee: 'Scroll down to see!',
    basedOnStudentProgress: 'Based on student progress taking 3 classes a week',

    nervousDuringInterviews:
      'I get nervous during interviews and work because of my English.',
    feelPreparedForInterviews:
      'I feel prepared for English interviews and more comfortable expressing myself at work.',
    passedMyInterviewAndPromoted: 'I passed my interviews and got promoted!',

    useLimitedEnglishWords: 'I can only use limited English words and idioms.',
    thinkAndExpressInEnglish:
      'I can think and express myself more freely in English.',
    comfortableUsingExpressionsUsedByNative:
      'I am comfortable using expressions used by native speakers daily.',

    troubleExpressingInSocial:
      'I have trouble expressing myself in a social situation.',
    canThinkAndExpressMyself:
      'I can think and express myself more freely in English.',
    comfortableJokeInEnglish:
      'I am comfortable joking around in English and making friends with native speakers.',

    amReadyToLearn: 'I am ready to learn!',
    months: 'Months',
    year: 'Year',
    now: 'Now',
    nativeLevel: 'Native Level',

    // Journey page
    chooseYourJourney: 'Choose your journey',
    journeyFrom:
      'Virtually live in more than 125 different English speaking cities around the world. Here is our recommendation to get you started.',

    // Learning plan page
    chooseYourLearningPage: 'Choose your learning plan',
    changePlanAnyTime: 'You can change your plan at any time.',
    month: 'month',
    monthly: 'monthly',
    annually: 'annually',
    classes: 'classes',
    class: 'class',
    weeks: 'weeks',
    off: 'off',
    total: 'total',
    selectLearningPlan: 'Select learning plan',
    theEssentials:
      'The essentials to provide you with the basics to improve your speaking skills.',
    thePerfect:
      'Perfect for English learning professionals and immigrants to improve their speaking skills.',
    theReserved: 'Reserved for the overachieving English learners.',
    upto140Minutes: 'Up to 140 minutes of speaking time',
    upto280Minutes: 'Up to 280 minutes of speaking time',
    upto420Minutes: 'Up to 420 minutes of speaking time',
    upto560Minutes: 'Up to 560 minutes of speaking time',
    upto4Classes:
      'Book up to 4 classes per 4 weeks with world-class instructors',
    upto8Classes:
      'Book up to 8 classes per 4 weeks with world-class instructors',
    upto12Classes:
      'Book up to 12 classes per 4 weeks with world-class instructors',
    upto16Classes:
      'Book up to 16 classes per 4 weeks with world-class instructors',
    mostPopular: 'Most popular',

    //Features
    'unlimited classes': 'Unlimited classes',
    'personalized AI feedback': 'Personalized AI feedback',
    'world-class native English coaches': 'World-class native English coaches',

    //Booking page
    bookYourFirstClass: 'Book your first session',
    classThatFitSchedule:
      'that fits your schedule. You can change your booking anytime 24 hours before the class.',
    recommendFirstClass:
      'Based on your answers, we recommend you to start with these classes below.',
    classesThatFitSchedule:
      'that fit your schedule. You can change your booking anytime 24 hours before the class.',
    select: 'Select',
    upTo: 'up to',
    pluralS: 'es',
    adjustedToYourTimeZone:
      'Note: The schedule is adjusted to your current timezone.',
    selectAtLeastOneClassToProceed: 'Select at least one class to proceed',
    pleaseDeselect: 'Please deselect',
    classesToProceed: 'classes to proceed',
    classToProceed: 'class to proceed',
    moreClassToProceed: 'more class to proceed',
    moreClassesToProceed: 'more classes to proceed',
    classesBooked: 'classes booked',
    classBooked: 'class booked',
    submitMyBooking: 'Submit my bookings',
    chooseADiferentLearningPlan: 'Choose a different journey',
    doesntFitSchedule: `Doesn't fit your schedule? Check out the full class calendar`,
    backToRecommendedClasses: `Back to recommended classes`,

    //Payment page
    summary: 'Summary',
    changeClassBooking: 'Choose a different learning plan',
    completeYourSignUp: 'Complete your sign-up',
    paymentMethod: 'Payment method',
    pay: 'Pay',
    start7WeekTrial: 'Start your free 7-days trial',
    discountCode: 'Discount code',
    noteYouWillBeBilledEveryYear: 'Note: You will be billed every year',
    noteYouWillBeBilledEvery4Weeks: 'Note: You will be billed every 4 weeks',
    noteYouWillBeBilledMonthly: 'Note: You will be billed every month',
    todaysTotal: `Today's Total`,
    class4per4week: '4 classes per 4 weeks',
    class8per4week: '8 classes per 4 weeks',
    class12per4week: '12 classes per 4 weeks',
    class16per4week: '16 classes per 4 weeks',
    billed: 'Billed',
    dueToday: 'due today',
    per4weeks: 'per 4 weeks',
    almostThere: 'Almost there',
    youWillHaveTry: 'You will have 7 days to try Immigo.',
    youCanCancelAvoidCharge:
      'You can cancel your subscription on our subscription page at anytime to avoid the charge.',
    youCanCancel:
      'You can cancel your subscription on our subscription page at anytime.',

    // Registration
    createYourAccount: 'Create your account',
    emailAddress: 'Email address',
    fullName: 'Full name',
    password: 'Password',
    confirmPassword: 'Confirm password',
    orContinueWith: 'Or continue with',
    signInWithYourAccount: 'Sign in to your account',
    pleaseEnterValidEmail: 'Please enter a valid email address',
    pleaseEnterFullName: 'Please enter your full name',
    pleaseEnterPassword: 'Please enter your password',
    passwordDoesNotMatch: 'Password does not match',
    forgotPassword: 'Forgot password?',

    //WhatsApp Modal
    stayOnTopOfYourClasses: 'Stay on top of your classes',
    weHelpStayOnTop:
      'We will help you stay on top of your class booking by sending you confirmation SMS whenever you book or cancel a class!',
    whatsAppNumber: 'WhatsApp Number',

    //This is coming soon
    thisClassComingSoon: 'This class is coming soon!',
    leaveNumberBelow:
      'Leave your number below if you want us to notify you when the class is open.',

    //Success Modal
    youAreIn: 'You are in!',
    haveYouIn: `We officially have you in our exclusive Immigo subscription program. Now let's complete your profile for your customized experience.`,
    continue: 'Continue',
  },
  es: {
    // Motivation page
    advanceMyCareer: 'Carrera professional',
    confidenceInSocialSetting: 'Socializar con confianza',
    expandMyKnowledge: 'Expandir mi conocimiento',
    other: 'Otro',
    welcomeToImmigo: '¡Vamos a conocerte mejor!',
    tellUsALittleBitAboutYourself:
      'Por favor cuéntanos un poco sobre ti para que podamos encontrar el plan de aprendizaje ideal para ti.',
    whyWorkingOnEnglish: '¿Por qué estas tratando de mejorar tu Inglés?',

    // Level page
    yourEnglishJourney: 'Nivel de Inglés',
    tellUsYourEnglishLevel:
      'Cuéntanos cuál es tu nivel de ingles para personalizar tu experiencia de aprendizaje.',
    beginner: 'Principiante',
    elementary: 'Elemental',
    preIntermediate: 'Pre-intermedio',
    intermediate: 'Intermedio',
    upperIntermediate: 'Intermedio superior',
    advanced: 'Avanzado',
    levelNotFit:
      'Nuestras clases están diseñadas para estudiantes de nivel elementary y superior.',

    // Level page waitlist modal
    a1ClassesComingSoon: '¡Clases A1 (Principiante) próximamente!',
    a1ComingSoonLeaveDetail:
      'Estamos planeando lanzar nuestras clases A1 (Principiante) pronto. ¡Deje su información de contacto aquí si desea que nos pongamos en contacto con usted cuando estén abiertos!',

    // Nationality page
    whatIsYourNationality: '¿Cuál es tu nacionalidad?',
    learnersFrom120Countries:
      'Nuestros estudiantes son de +120 paises diferentes. Saber de donde eres nos ayuda a conocer mejor tu necesidades para aprender Inglés.',
    letsFindYourCountry: `¡Vamos a encontrar tu país!`,
    chooseYourCountryPressSubmit: 'Selecciona tu país y oprime "Enviar"',
    submit: 'Enviar',

    // Based Country page
    whichCountryDoYouLiveIn: '¿En cuál país vives?',
    manyAreImmigrants:
      'Cuéntanos de donde eres para encontrar la sesión que mejor se adaptan a tus necesidades.',

    // Program page
    accelarationProgram: 'Programa de aceleración.',
    next6Months:
      'Por los próximos 6 meses, te guiaremos a través de un programa de aprendizaje diseñado acorde a la estructura CEFR, ayudándote a avanzar al siguiente nivel.',
    advancedEnglishExpressions: 'Expresiones Avanzadas en Inglés',
    pronunciation: 'Pronunciación',
    communicationAndPublicSpeaking: 'Comunicación y Oratoria',
    vocabulary: 'Vocabulario',
    grammar: 'Gramática',

    //Loading
    findingRightPlanForYou: 'Encontrando el plan perfecto para ti...',
    loadingCustomizedClasses: 'Cargando tus clases personalizadas...',

    // Vision page
    envisionYourSelf: 'Visualiza tu progreso',
    basedOnWhatYouToldJourney:
      'Basado en lo que nos dijiste, asi es como se vera tu trayecto en Immigo',
    scrollDownToSee: '¡Desplázate hacia abajo para ver!',
    basedOnStudentProgress:
      'Basado en el progreso de un estudiante que toma 3 clases por semana.',

    nervousDuringInterviews:
      'Me pongo nervioso durante entrevistas y en el trabajo debido a mi inglés.',
    feelPreparedForInterviews:
      'Me siento preparado para entrevistas en inglés y más comodo expresandome en el trabajo.',
    passedMyInterviewAndPromoted: 'Pase mis entrevistas y fuí promovido.',

    useLimitedEnglishWords:
      'Solo puedo utilizar una cantidad limitada de palabras y modismos.',
    thinkAndExpressInEnglish: 'Puedo pensar y expresarme libremente en inglés.',
    comfortableUsingExpressionsUsedByNative:
      'Me siento comodo utilizando expresiones utilizadas por hablantes nativos diariamente.',

    troubleExpressingInSocial:
      'Tengo problema expresandome en ambientes sociales.',
    canThinkAndExpressMyself: 'Puedo pensar y expresarme libremente en inglés.',
    comfortableJokeInEnglish:
      'Me siento comodo bromeando en inglés y haciendo amistad con hablantes nativos.',

    amReadyToLearn: '¡Estoy preparado para aprender!',
    months: 'Meses',
    year: 'Año',
    now: 'Ahora',
    nativeLevel: 'Nivel Nativo',

    // Journey page
    chooseYourJourney: 'Escoge tu viaje',
    journeyFrom:
      'Vive virtualmente en más de 125 ciudades diferentes de habla inglesa en todo el mundo. Aquí está nuestra recomendación para comenzar.',

    // Learning plan page
    chooseYourLearningPage: 'Escoge tu plan de aprendizaje',
    changePlanAnyTime: 'Puedes modificar tu plan en cualquier momento.',
    month: 'mes',
    monthly: 'mensualmente',
    annually: 'anualmente',
    classes: 'clases',
    class: 'clase',
    weeks: 'semanas',
    off: 'dto.',
    total: 'total',
    selectLearningPlan: 'Prueba gratis por 7 días',
    theEssentials:
      'Lo esencial para proveerte con los basico para mejorar tus habilidades para hablar.',
    thePerfect:
      'Perfecto para aquellos estudiantes de ingles que son profesionales o inmigrantes y quieren mejorar sus habilidades para hablar.',
    theReserved:
      'Reservado para estudiantes de ingles que quieren superar sus metas.',
    upto140Minutes: 'Máximo de 140 minutos de tiempo de palabra',
    upto280Minutes: 'Máximo de 280 minutos de tiempo de palabra',
    upto420Minutes: 'Máximo de 420 minutos de tiempo de palabra',
    upto560Minutes: 'Máximo de 560 minutos de tiempo de palabra',
    upto4Classes:
      'Reserve máximo de 4 clases cada 4 semanas con instructores de clase mundial',
    upto8Classes:
      'Reserve máximo de 8 clases cada 4 semanas con instructores de clase mundial',
    upto12Classes:
      'Reserve máximo de 12 clases por 4 semanas con instructores de clase mundial',
    upto16Classes:
      'Reserve máximo de 16 clases por 4 semanas con instructores de clase mundial',
    mostPopular: 'Más popular',

    'unlimited classes': 'Clases ilimitadas de Immigo',
    'personalized AI feedback': 'Comentarios personalizados de IA',
    'world-class native English coaches':
      'Instructores nativos de inglés de clase mundial.',

    //Booking page
    bookYourFirstClass: 'Reserva tu primera sesión',
    classThatFitSchedule:
      'clase que encaje con tu horario. Puedes cambiar tu reserva siempre 24 horas antes de la clase.',
    recommendFirstClass:
      'Basado en tus respuestas, le recomendamos comenzar con estas clases a continuación.',
    classesThatFitSchedule:
      'clases que encajen con tu horario. Puedes cambiar tu reserva siempre 24 horas antes de la clase.',
    select: 'Selecciona',
    upTo: 'máximo de',
    pluralS: 's',
    adjustedToYourTimeZone:
      'Nota: El calendario se ajusta a tu zona horaria actual.',
    selectAtLeastOneClassToProceed:
      'Selecciona al menos una clase para proceder',
    pleaseDeselect: 'Deselecciona',
    classesToProceed: 'clases para proceder',
    classToProceed: 'clase para proceder',
    moreClassToProceed: 'más clase para proceder',
    moreClassesToProceed: 'más clases para proceder',
    classesBooked: 'clases reservadas',
    classBooked: 'clase reservada',
    submitMyBooking: 'Enviar mis reservas',
    chooseADiferentLearningPlan: 'Escoger otro viaje',
    doesntFitSchedule: `¿No se ajusta a tu horario? Consulta el calendario de clases completo`,
    backToRecommendedClasses: `Volver a las clases recomendadas`,

    //Payment page
    summary: 'Resumen',
    changeClassBooking: 'Escoger otro plan de aprendizaje',
    completeYourSignUp: 'Completa tu registro',
    paymentMethod: 'Método de pago',
    pay: 'Pagar',
    start7WeekTrial: 'Empieza la prueba gratuita de 7 dias',
    discountCode: 'Código descuento',
    noteYouWillBeBilledEveryYear: 'Nota: Se te facturará anualmente.',
    noteYouWillBeBilledEvery4Weeks: 'Nota: Se te facturará cada 4 semanas',
    noteYouWillBeBilledMonthly: 'Nota: Se te facturará mensualmente',
    todaysTotal: `Total de hoy`,
    class4per4week: '4 clases por 4 semanas',
    class8per4week: '8 clases por 4 semanas',
    class12per4week: '12 clases por 4 semanas',
    class16per4week: '16 clases por 4 semanas',
    billed: 'Facturado',
    dueToday: 'due today',
    per4weeks: 'por 4 semanas',
    almostThere: 'Casi llegamos',
    youWillHaveTry: 'Vas a tener 7 días para probar Immigo.',
    youCanCancelAvoidCharge:
      'Puedes cancelar tu suscripción en nuestra página de suscripción en cualquier momento para evitar el cargo.',
    youCanCancel:
      'Puedes dejar de suscribirte a nuestra página de suscripción en cualquier momento.',

    // Registration
    createYourAccount: 'Crea una cuenta',
    emailAddress: 'Email',
    fullName: 'Nombre completo',
    password: 'Contraseña',
    confirmPassword: 'Confirmar contraseña',
    orContinueWith: 'O ingresa con',
    signInWithYourAccount: 'Inicia sesión',
    pleaseEnterValidEmail: 'Ingrese un email válido',
    pleaseEnterFullName: 'Ingrese tu nombre completo',
    pleaseEnterPassword: 'Ingrese una contraseña',
    passwordDoesNotMatch: 'La contraseña no coincide',
    forgotPassword: 'Recuperar contraseña',

    //WhatsApp Modal
    stayOnTopOfYourClasses: 'No olvides tus clases',
    weHelpStayOnTop:
      '¡Te ayudaremos a seguir con tu reserva de clase enviándote un SMS de confirmación cada vez que reservas o cancelas una clase!',
    whatsAppNumber: 'Número de WhatsApp',

    //This is coming soon
    thisClassComingSoon: '¡Esta clase estará pronto disponible!',
    leaveNumberBelow:
      '¡Deja tu información de contacto aquí si deseas que nos pongamos en contacto contigo cuando estén disponibles!',

    //Success Modal
    youAreIn: '¡Lo lograste!',
    haveYouIn: `Oficialmente estás en nuestro exclusivo programa de suscripción a Immigo. Ahora vamos a terminar tu perfil para tu experiencia personalizada.`,
    continue: 'Continuar',
  },
  // ko: {
  //   // Motivation page
  //   advanceMyCareer: 'Advance my career',
  //   confidenceInSocialSetting: 'Confidence in social settings',
  //   expandMyKnowledge: 'Expand my knowledge',
  //   other: 'Other',
  //   welcomeToImmigo: 'Welcome To Immigo!',
  //   tellUsALittleBitAboutYourself:
  //     'Please tell us a little bit about yourself so that we can find you the right learning plan and classes for you.',
  //   whyWorkingOnEnglish: 'Why are you working on your English speaking skill?',

  //   // Level page
  //   yourEnglishJourney: 'Your English journey',
  //   tellUsYourEnglishLevel:
  //     'Tell us your English level to better customize your learning experience.',
  //   beginner: 'Beginner',
  //   elementary: 'Elementary',
  //   preIntermediate: 'Pre-intermediate',
  //   intermediate: 'Intermediate',
  //   upperIntermediate: 'Upper intermediate',
  //   advanced: 'Advanced',
  //   levelNotFit:
  //     'Our classes are designed for learners who are intermediate and above.',

  //   // Nationality page
  //   whatIsYourNationality: 'What is your nationality?',
  //   learnersFrom120Countries:
  //     'Our learners are from 120+ different countries. Knowing where you are from helps us better understand your English learning needs.',
  //   letsFindYourCountry: `Let's find your country!`,
  //   chooseYourCountryPressSubmit:
  //     'Please choose your country and press "Submit"',
  //   submit: 'Submit',

  //   // Based Country page
  //   whichCountryDoYouLiveIn: 'Which country do you live in?',
  //   manyAreImmigrants:
  //     'Many of our learners are immigrants to English speaking countries. Tell us where you are from, so that we can find you classes that better fit your needs whether you are an immigrant or not.',

  //   //Loading
  //   findingRightPlanForYou: '나에게 맞는 플랜 찾는중...',
  //   loadingCustomizedClasses: '',

  //   // Learning plan page
  //   chooseYourLearningPage: '나에게 맞는 플랜 고르기',
  //   changePlanAnyTime: '플랜은 구매하시고 언제나 바꿀 수 있습니다',
  //   monthly: 'Monthly',
  //   annually: 'Annually',
  //   classes: 'classes',
  //   class: 'class',
  //   weeks: 'weeks',
  //   off: 'off',
  //   total: '합계',
  //   selectLearningPlan: '수업 플랜 선택',
  //   theEssentials: '베이직',
  //   thePerfect: '프리미엄',
  //   theReserved: '프리미엄 플러스',
  //   upto140Minutes: '매달 최대 140분 영어토론',
  //   upto280Minutes: '매달 최대 280분 영어토론',
  //   upto420Minutes: '매달 최대 420분 영어토론',
  //   upto4Classes: '매주 1회 (총 52 회)',
  //   upto8Classes: '매주 2회 (총 104 회)',
  //   upto12Classes: '매주 3회 (총 156 회)',
  //   mostPopular: '인기 상품',

  //   //Booking page
  //   bookYourFirstClass: 'Book your first class',
  //   classThatFitSchedule:
  //     'that fits your schedule. You can change your booking anytime 24 hours before the class.',
  //   classesThatFitSchedule:
  //     'that fit your schedule. You can change your booking anytime 24 hours before the class.',
  //   select: 'Select',
  //   upTo: 'up to',
  //   pluralS: 'es',
  //   adjustedToYourTimeZone:
  //     'Note: The schedule is adjusted to your current timezone.',
  //   selectAtLeastOneClassToProceed: 'Select at least one class to proceed',
  //   pleaseDeselect: 'Please deselect',
  //   classesToProceed: 'classes to proceed',
  //   classToProceed: 'class to proceed',
  //   moreClassToProceed: 'more class to proceed',
  //   moreClassesToProceed: 'more classes to proceed',
  //   classesBooked: 'classes booked',
  //   classBooked: 'class booked',
  //   submitMyBooking: 'Submit my bookings',
  //   chooseADiferentLearningPlan: 'Choose a different learning plan',

  //   //Payment page
  //   summary: '결제 하기',
  //   changeClassBooking: 'Change class bookings',
  //   completeYourSignUp: 'Complete your sign-up',
  //   paymentMethod: '결제 방법',
  //   pay: '결제',
  //   start7WeekTrial: '일주일 무료체험 시작하기',
  //   discountCode: '할인 코드',
  //   noteYouWillBeBilledEveryYear: 'Note: You will be billed every year',
  //   noteYouWillBeBilledEvery4Weeks: 'Note: You will be billed every 4 weeks',
  //   todaysTotal: `오늘 결제 금액`,
  //   class4per4week: '4 classes per 4 weeks',
  //   class8per4week: '8 classes per 4 weeks',
  //   class12per4week: '12 classes per 4 weeks',
  //   billed: 'Billed',
  //   dueToday: 'due today',
  //   per4weeks: '4주',
  //   almostThere: 'Almost there',
  //   youWillHaveTry:
  //     '일주일간 이미고를 체험해보세요! 체험동안 세션을 3회 참가할 수 있습니다.',
  //   youCanCancelAvoidCharge:
  //     'You can cancel your subscription on our subscription page at anytime to avoid the charge.',
  //   youCanCancel:
  //     'You can cancel your subscription on our subscription page at anytime.',

  //   // Registration
  //   createYourAccount: '회원가입',
  //   emailAddress: '이메일',
  //   fullName: '이름 (영문)',
  //   password: '비밀번호',
  //   confirmPassword: '비밀번호 확인',
  //   orContinueWith: '다른 방법', // awk
  //   signInWithYourAccount: '로그인',
  //   pleaseEnterValidEmail: '유효한 이메일을 입력해 주세요',
  //   pleaseEnterFullName: '이름을 입력해주세요',
  //   pleaseEnterPassword: '비밀번호를 6자 이상 입력해주세요',
  //   passwordDoesNotMatch: '비밀번호 일치 여부를 확인해주세요',
  //   forgotPassword: '비밀번호를 잊으셨나요?',

  //   //WhatsApp Modal
  //   stayOnTopOfYourClasses: 'Stay on top of your classes',
  //   weHelpStayOnTop:
  //     'We will help you stay on top of your class booking by sending you confirmation SMS whenever you book or cancel a class!',
  //   whatsAppNumber: 'WhatsApp Number',

  //   //Success Modal
  //   youAreIn: 'You are in!',
  //   haveYouIn: `We officially have you in our exclusive Immigo subscription program. Now let's complete your profile for your customized experience.`,
  //   continue: 'Continue',
  // },
});

export let stringsSchedulePage = new LocalizedStrings({
  en: {
    classSchedule: 'Immigo class schedule',
  },

  es: {
    classSchedule: 'Immigo calendario de la clase',
  },
});
