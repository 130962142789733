import cx from 'classix';
import { useEffect, useState } from 'react';
import { LearningPathRequirementCard } from '../../../components/learningPath/learingPathRequirementCard/learningPathRequirementCard';
import {
  AcademicCapIcon,
  ChatBubbleLeftEllipsisIcon,
  DocumentTextIcon,
  MegaphoneIcon,
  UserIcon,
} from '@heroicons/react/24/outline';
import { PrimaryButton } from '../../../components/buttons/primary';
import { stringsPricingPage } from '../../../util/localization';

const animationDuration = 300;

const classTypeToIcon: any = {
  idiom: ChatBubbleLeftEllipsisIcon,
  vocab: AcademicCapIcon,
  pronunciation: MegaphoneIcon,
  'free-talking': UserIcon,
  grammar: DocumentTextIcon,
};

export const LevelProgram = (props: {
  cefrLevel: string;
  onNextClick: Function;
}) => {
  const [clicked, setClicked] = useState(false);
  const [loaded, setLoaded] = useState(false);

  const { cefrLevel, onNextClick } = props;

  useEffect(() => {
    setLoaded(true);
  }, []);
  return (
    <div
      className={cx(
        `mx-auto max-w-7xl md:px-6 xl:px-8 transition-all ease-in duration-${animationDuration} translate-x-0`,
        !clicked && loaded ? 'opacity-100' : 'opacity-0 -translate-x-20',
      )}
    >
      <div className="grid md:grid-cols-2 grid-cols-1 gap-4">
        <div className="bg-blue-300 rounded-md md:p-8 p-4 flex md:flex-col md:justify-between">
          <div></div>
          <div className="md:flex justify-center md:my-0 my-auto md:mr-0 mr-4">
            <img
              className="md:w-48 md:h-auto w-64 h-auto"
              src="https://firebasestorage.googleapis.com/v0/b/immigo-application-8ba25.appspot.com/o/website_photos%2Fimmersion%2Fcefr-logo-white.png?alt=media&token=4e624dc0-039a-4648-8bef-0d4543d40d0d"
            />
          </div>
          <div>
            <div className="md:text-4xl text-2xl text-blue-500 font-semibold md:my-6">
              {`${cefrLevel} ${stringsPricingPage.accelarationProgram}`}
            </div>
            <div className="md:text-md text-sm text-white font-semibold">
              {`${stringsPricingPage.next6Months}`}
            </div>
          </div>
        </div>
        <div className="rounded-md p-2 flex flex-col gap-2">
          <LearningPathRequirementCard
            colorTheme="green"
            Icon={classTypeToIcon['idiom']}
            title={stringsPricingPage.advancedEnglishExpressions}
          />
          <LearningPathRequirementCard
            colorTheme="orange"
            Icon={classTypeToIcon['pronunciation']}
            title={stringsPricingPage.pronunciation}
          />
          <LearningPathRequirementCard
            colorTheme="blue"
            Icon={classTypeToIcon['free-talking']}
            title={stringsPricingPage.communicationAndPublicSpeaking}
          />
          <LearningPathRequirementCard
            colorTheme="yellow"
            Icon={classTypeToIcon['vocab']}
            title={stringsPricingPage.vocabulary}
          />
          <LearningPathRequirementCard
            colorTheme="red"
            Icon={classTypeToIcon['grammar']}
            title={stringsPricingPage.grammar}
          />
        </div>
      </div>
      <div className="mt-4">
        <PrimaryButton
          text={stringsPricingPage.continue}
          onClick={onNextClick}
        />
      </div>
    </div>
  );
};
