import { useDispatch, useSelector } from 'react-redux';
import StackedCardRadio from '../../../components/forms/stackedCardRadio/stackedCardRadio';
import { updateUserData } from '../../../firebase/users/users';
import { UserData } from '../../../types/user';
import { useState } from 'react';
import { stringsPricingPage } from '../../../util/localization';
import { AudioPlayerButton } from '../../../components/buttons/audioPlayer/audioPlayerButton';
import { trackPricingPageEvents } from '../../../features/PricingPage/analytics';
import { WhatsappIcon } from 'react-share';
import PhoneInput from 'react-phone-number-input';
import VerticalMessageModal from '../../../components/modals/verticalMessageModal/verticalMessageModal';
import RedAlert from '../../../components/alerts/redAlert';
import TextInput from '../../../components/forms/textInput';
import { validateEmail } from '../../../util/validation';
import { createLevelWaitlist } from '../../../api/waitlist';

export const EnglishLevel = (props: {
  onNextClick: Function;
  setValue: Function;
}) => {
  const { onNextClick, setValue } = props;
  const [error, setError] = useState('');
  const [audioPlaying, setAudioPlaying] = useState<string>();
  const [openA1WaitList, setOpenA1WaitList] = useState(false);
  const [phoneNumber, setPhoneNumber] = useState('');
  const [email, setEmail] = useState('');
  const [modalError, setModalError] = useState('');

  const choices = [
    {
      name: `🥚 A1 (${stringsPricingPage.beginner})`,
      audio:
        'https://firebasestorage.googleapis.com/v0/b/immigo-application-8ba25.appspot.com/o/level_samples%2Fa1_audio.mp3?alt=media&token=d4a832fa-4bfb-49e0-9488-13be15aa24b5',
      onClick: async () => {
        // setValue('A1');
        setOpenA1WaitList(true);
        setError(stringsPricingPage.levelNotFit);
        trackPricingPageEvents('Opened level waitlist modal', {
          cefrLevel: 'A1',
        });
      },
    },
    {
      name: `🐣 A2 (${stringsPricingPage.elementary})`,
      audio:
        'https://firebasestorage.googleapis.com/v0/b/immigo-application-8ba25.appspot.com/o/level_samples%2Fa2_audio.mp3?alt=media&token=bff4829e-8490-48f9-960c-e01948eb1fe9',
      onClick: async () => {
        setValue('A2');
        onNextClick();
      },
    },
    {
      name: `📈 B1 (${stringsPricingPage.preIntermediate})`,
      audio:
        'https://firebasestorage.googleapis.com/v0/b/immigo-application-8ba25.appspot.com/o/level_samples%2Fb1_audio.mp3?alt=media&token=e69cf3c4-944c-4d45-b95c-03bfe91d3779',
      onClick: async () => {
        setValue('B1');
        onNextClick();
      },
    },
    {
      name: `🧑‍🎓️ B2 (${stringsPricingPage.intermediate})`,
      audio:
        'https://firebasestorage.googleapis.com/v0/b/immigo-application-8ba25.appspot.com/o/level_samples%2Fb2_audio.mp3?alt=media&token=ade11c25-28af-48ef-8ff4-3a7db5a3e66c',
      onClick: async () => {
        setValue('B2');
        onNextClick();
      },
    },
    {
      name: `🤓 C1 (${stringsPricingPage.upperIntermediate})`,
      audio:
        'https://firebasestorage.googleapis.com/v0/b/immigo-application-8ba25.appspot.com/o/level_samples%2Fc1_audio.mp3?alt=media&token=c9711cbd-2011-4e9e-932e-995f4be5a7b7',
      onClick: async () => {
        setValue('C1');
        onNextClick();
      },
    },
    {
      name: `🚀 C2 (${stringsPricingPage.advanced})`,
      audio:
        'https://firebasestorage.googleapis.com/v0/b/immigo-application-8ba25.appspot.com/o/level_samples%2Fc2_clip.mp3?alt=media&token=918fa252-5f08-4f42-a709-d4de454fb688',
      onClick: async () => {
        setValue('C2');
        onNextClick();
      },
    },
  ];
  return (
    <div className="md:px-48">
      <VerticalMessageModal
        mainMessage={stringsPricingPage.a1ClassesComingSoon}
        subMessage={stringsPricingPage.a1ComingSoonLeaveDetail}
        theme="info"
        icon="exclamation"
        buttonText={stringsPricingPage.submit}
        isOpen={openA1WaitList}
        stayOpenOnPrimaryClick
        onCloseModal={() => {
          setOpenA1WaitList(false);
        }}
        onButtonClick={async () => {
          if (phoneNumber && email) {
            if (!validateEmail(email)) {
              setModalError('Please enter a valid email.');
              return;
            }
            await createLevelWaitlist(phoneNumber, email, 'A1');
            trackPricingPageEvents('Submitted level waitlist modal', {
              cefrLevel: 'A1',
            });
            setOpenA1WaitList(false);
          } else if (!phoneNumber) {
            setModalError(
              'Please enter a valid phone number with your country code.',
            );
          } else if (!email) {
            setModalError('Please enter your email.');
          }
        }}
      >
        <div className="my-10 max-w-[400px] mx-auto">
          <div className="block text-sm font-medium leading-6 text-gray-900 text-left mb-2 flex items-center">
            <WhatsappIcon size={20} round className="mr-2" />{' '}
            {stringsPricingPage.whatsAppNumber}
          </div>
          <PhoneInput
            value={phoneNumber}
            onChange={(n: string) => {
              setPhoneNumber(n);
            }}
          />
          <div className="block text-sm font-medium leading-6 text-gray-900 text-left mb-2 mt-2 flex items-center">
            Email
          </div>
          <TextInput
            label="Email"
            value={email}
            onChange={(v: string) => setEmail(v)}
          />
        </div>
        {modalError ? <RedAlert text={modalError} /> : null}
      </VerticalMessageModal>
      <div className="text-2xl text-center dark:text-white">
        {stringsPricingPage.yourEnglishJourney}
      </div>
      <div className="mt-6 text-center dark:text-white">
        {stringsPricingPage.tellUsYourEnglishLevel}
      </div>
      <div className="mt-6 flex">
        <div className="w-full">
          <StackedCardRadio choices={choices} error={error} />
        </div>
        <div className="flex flex-col justify-between">
          {choices.map((choice) => {
            return (
              <div className="flex justify-center py-2">
                <AudioPlayerButton
                  audioID={choice.name}
                  hideTime
                  audioRecordingURL={choice.audio}
                  stopAudio={audioPlaying !== choice.name}
                  onPlayClick={() => {
                    trackPricingPageEvents(
                      'Played CEFR audio in pricing page',
                      {
                        cefrLevel: choice.name,
                      },
                    );
                    setAudioPlaying(choice.name);
                  }}
                />
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};
