import { useEffect, useState } from 'react';
import BulletSteps from '../../../components/steps/bulletProgress';
import { ClassPrepHeadPhoneOnPage } from './pages/headPhoneOnPage';
import {
  ClassPrepAllowMicPage,
  ClassPrepSetUpState,
} from './pages/allowMicPage';
import { BookingData } from '../../../firebase/subscription/subscription';
import { PrimaryButton } from '../../../components/buttons/primary';
import { trackClassLinkRedirect } from '../analytics';
import { PostClassSurveyPage } from './pages/surveyPage/surveyPage';

function pageSelector(pageName: string) {
  switch (pageName) {
    case 'mic':
      return 1;
    case 'headphone':
      return 0;
    case 'survey':
      return 2;
    default:
      return 0;
  }
}

function stepSelector(pageName: string) {
  const initialSteps = [
    {
      name: 'headphone',
      href: '?page=headphone',
      status: 'current',
    },
    {
      name: 'mic',
      href: '?page=mic',
      status: 'upcoming',
    },
  ];

  switch (pageName) {
    case 'mic':
      initialSteps[0].status = 'complete';
      initialSteps[1].status = 'current';
      return initialSteps;
    case 'headphone':
      return initialSteps;
    default:
      return initialSteps;
  }
}

export interface AudioDevice {
  name: string;
  id: string;
  kind: string;
}

export const ClassSetUpContainer = (props: {
  onSetUpFinish: Function;
  classSessionID?: string;
  booking?: BookingData;
  skipMicAccess?: boolean;
}) => {
  const { onSetUpFinish, booking, classSessionID, skipMicAccess } = props;
  const urlParams = new URLSearchParams(window.location.search);
  const page = urlParams.get('page');
  const startMili = booking?.startMili;
  const durationMili = booking?.durationMili;
  // const recordingBuffer = 1000 * 60 * 5; // Classes can sometimes run 5 minutes over.

  const [steps, setSteps] = useState(stepSelector(page ?? ''));
  const [currentPage, setCurrentPage] = useState(pageSelector(page ?? ''));
  const [classEnded, setClassEnded] = useState(false);
  const [timeNow, setTimeNow] = useState(new Date().valueOf());
  const [currentState, setCurrentState] =
    useState<ClassPrepSetUpState>('setup');
  const [audioDevices, setAudioDevices] = useState<Array<AudioDevice>>([]);
  const [speakerDevices, setSpeakerDevices] = useState<Array<AudioDevice>>([]);
  const [selectedDevice, setSelectedDevice] = useState<
    AudioDevice | undefined
  >();

  function trackTime() {
    setInterval(() => {
      setTimeNow(new Date().valueOf());
    }, 1000);
  }

  function updateDeviceDetail() {
    navigator.mediaDevices.enumerateDevices().then(function (devices) {
      const newAudioDevices: Array<AudioDevice> = [];
      const newAudioOutputDevices: Array<AudioDevice> = [];
      let defaultDevice: AudioDevice;
      let defaultOutputDevice: AudioDevice;
      devices.forEach(function (device) {
        const deviceData = {
          name: device.label,
          kind: device.kind,
          id: device.deviceId,
        };
        if (
          device.kind !== 'videoinput' &&
          device.kind !== 'audiooutput' &&
          !newAudioDevices.find((d_) => d_.id === device.deviceId)
        ) {
          if (device.deviceId === 'default') {
            defaultDevice = deviceData;
          } else {
            newAudioDevices.push(deviceData);
          }
        } else if (device.kind === 'audiooutput') {
          if (device.deviceId === 'default') {
            defaultOutputDevice = deviceData;
          } else {
            newAudioOutputDevices.push(deviceData);
          }
        }
        // console.log(device.kind + ": " + device.label +
        //         " id = " + device.deviceId);
      });
      setSelectedDevice(
        newAudioDevices.find((device) =>
          defaultDevice?.name?.includes(device.name),
        ),
      );
      console.log(newAudioDevices);
      setAudioDevices(newAudioDevices);
      setSpeakerDevices(newAudioOutputDevices);
    });
  }

  useEffect(() => {
    if (
      startMili !== undefined &&
      durationMili !== undefined &&
      startMili + durationMili < timeNow
    ) {
      setClassEnded(true);
      setCurrentState('endofclass');
      // Need to end the recording here.
    }
  }, [booking, timeNow]);

  useEffect(() => {
    if (classEnded) {
      setCurrentPage(1);
      setCurrentState('endofclass'); // Show finished class page
      trackClassLinkRedirect('Class ended', {
        sessionID: classSessionID,
        timeNow: new Date().valueOf(),
      });
      trackTime(); // Start tracking time
    }
  }, [classEnded]);

  useEffect(() => {
    trackTime();
    updateDeviceDetail();
  }, []);

  useEffect(() => {
    if (skipMicAccess) {
      onSetUpFinish();
      setCurrentState('transcribing');
      console.log('skipping mic access');
    }
  }, [skipMicAccess]);

  useEffect(() => {
    console.log(selectedDevice);
  }, [selectedDevice]);

  useEffect(() => {
    if (currentState === 'survey') {
      setCurrentPage(2);
    }
  }, [currentState]);

  useEffect(() => {
    if (page === 'survey') setCurrentState('survey');
  }, [page]);

  function renderPage() {
    const pages = [
      <ClassPrepHeadPhoneOnPage
        onClick={() => {
          urlParams.set('page', 'mic');
          urlParams.set('deviceId', selectedDevice?.id || '');
          window.location.search = urlParams.toString();
          console.log('hello');
        }}
        audioOutputDevices={speakerDevices}
        defaultAudio={selectedDevice}
        audioDevices={audioDevices}
        onDeviceChange={(deviceId: string) => {
          setSelectedDevice(
            audioDevices.find((device) => device.id === deviceId),
          );
        }}
      />,
      <ClassPrepAllowMicPage
        onReadyClick={() => {
          onSetUpFinish();
          setCurrentState('transcribing');
        }}
        onUploadFinished={() => {
          urlParams.set('page', 'survey');
          window.location.search = urlParams.toString();
          setCurrentState('survey');
        }}
        state={currentState}
        classSessionID={classSessionID}
        defaultAudioInputDeviceId={urlParams.get('deviceId') ?? undefined}
        bookingID={booking?.id}
        bookerID={booking?.bookerID}
      />,
      booking?.id ? (
        <PostClassSurveyPage
          userID={booking?.bookerID}
          classSessionID={classSessionID}
          bookingID={booking?.id}
        />
      ) : null,
    ];
    return pages[currentPage];
  }

  return (
    <div className="flex flex-col items-center my-6">
      {currentState === 'setup' ? (
        <div className="md:text-5xl text-3xl text-center">
          🚀 Let's get you ready for class!
        </div>
      ) : null}
      <div className="mt-12 mb-6">{booking ? renderPage() : null}</div>
      {currentState === 'transcribing' ? (
        <div className="flex">
          <PrimaryButton
            text="My class ended!"
            onClick={() => {
              trackClassLinkRedirect('Clicked class ended', {
                sessionID: classSessionID,
              });
              setCurrentState('endofclass');
            }}
          />
        </div>
      ) : null}
      {currentState === 'setup' ? <BulletSteps steps={steps} /> : null}
    </div>
  );
};
