import { CheckCircleIcon } from '@heroicons/react/24/outline';
import { cx } from 'classix';
import moment from 'moment-with-locales-es6';
import { useEffect, useState } from 'react';
import CardPaymentForm from '../../../components/forms/payment/card';
import { TotalTable } from '../../../components/registration/paymentpage/totaltable';
import {
  classesSubscriptionTiers,
  classesSubscriptionTiersYearly,
  couponMap,
} from '../../../config/stripe';
import { trackBookingAnalytics } from '../../../features/Booking/analytics';
import { PricingOptionBox } from '../../../features/Booking/pricingOptionBox';
import { trackUserReferral } from '../../../firebase/configuration';
import { useAffiliateDiscountFromFirestore } from '../../../firebase/referralCode/referralCode';
import { useReferralCode } from '../../../providers/referral-provider';
import { getFirstNameFromFullName } from '../../../util/helper';
import { capitalizeFirstLetter } from '../../../util/standardization';
import { FrequencyPricng } from '../PricingPage';
import TextInput from '../../../components/forms/textInput';
import { stringsPricingPage } from '../../../util/localization';
import { updateUserData } from '../../../firebase/users/users';
import { getAllLearningPlans } from '../../../api/learningPlans';
import { getDiscountCode, getPriceDiscount } from '../../../api/discountCodes';
import { LearningPlanOptionBox } from '../../../components/productBoxes/learningPlanOptionBox';
import { dayInMili } from '../../../usefuldata/mili';

const animationDuration = 300;

export function CheckOutPage(props: {
  page: number;
  trial: boolean;
  setPage: Function;
  setLoading: Function;
  userData: any;
  frequency: FrequencyPricng;
  onSuccess?: Function;
  refCode?: string;
  currency: string;
  subOption?: number;
}) {
  let {
    trial,
    page,
    setPage,
    subOption,
    setLoading,
    userData,
    onSuccess,
    frequency,
    currency,
  } = props;
  const search = new URLSearchParams(window.location.search);
  const viaToken = search.get('via');

  const [clicked, setClicked] = useState(false);
  const [loaded, setLoaded] = useState(false);
  const [error, setError] = useState<undefined | string>(undefined);
  const [discountCode, setDiscountCode] = useState(viaToken ?? '');
  const [discount, setDiscount] = useState<any>();
  const [trialLength, setTrialLength] = useState(7);
  const [chosenPlan, setChosenPlan] = useState<any>();

  const tiers =
    frequency.value === 'annually'
      ? classesSubscriptionTiersYearly
      : classesSubscriptionTiers;

  const [totalTableItems, setTotalTableItems] = useState<any[]>([]);
  console.log(tiers);

  const [totalPrice, setTotalPrice] = useState(0);

  const chargeDate = trial
    ? moment().locale(navigator.language).add(7, 'd')
    : frequency.value === 'annually'
    ? moment().locale(navigator.language).add(1, 'y')
    : moment().locale(navigator.language).add(4, 'w');

  useEffect(() => {
    onLoad();
  }, []);

  async function onLoad() {
    // @ts-ignore
    rewardful('ready', function () {
      console.log('Rewardful Ready!');
    });
    // if (viaToken) {
    //   setTrialLength(14);
    // }
    const plans = await getAllLearningPlans();
    await onDiscountCodeInputBlur();
    const chosenPlanId = window.sessionStorage.getItem('price_id');
    const chosenPlan_ = plans?.find((plan: any) => {
      const planId = plan.priceId;
      return planId === chosenPlanId;
    });

    console.log(chosenPlan_);
    console.log(chosenPlan_?.prices?.[currency]);
    setChosenPlan({
      priceId: chosenPlan_?.priceId,
      name: chosenPlan_?.name,
      id: chosenPlan_?.id,
      description: chosenPlan_?.description,
      price: chosenPlan_?.prices?.[currency].amount,
      intervalUnit: chosenPlan_.interval,
      intervalCount: chosenPlan_.intervalCount,
      nextBillingDate: moment()
        .add(chosenPlan_.intervalCount, chosenPlan_.interval)
        .format('MMMM D, YYYY'),
      features: chosenPlan_.features,
    });
    setLoaded(true);
  }

  async function onDiscountCodeInputBlur() {
    const chosenPlanId = window.sessionStorage.getItem('price_id');

    console.log(discountCode);
    if (!chosenPlanId || !chosenPlan || !discountCode) return;
    const discountObject = await getPriceDiscount(chosenPlanId, discountCode);

    console.log(discountObject);
    if (!discountObject) {
      setTotalTableItems([
        {
          name: chosenPlan.name,
          price: chosenPlan.price,
          isDiscount: false,
        },
      ]);
      setTotalPrice(chosenPlan.price);
      setDiscount(undefined);
      return;
    }

    setTotalTableItems([
      {
        name: chosenPlan.name,
        price: chosenPlan.price,
        isDiscount: false,
      },
      {
        name: discountCode,
        price: discountObject.percent_off
          ? (discountObject.percent_off * chosenPlan.price) / 100
          : 0,
        isDiscount: true,
      },
    ]);

    setTotalPrice(
      chosenPlan.price -
        (discountObject.percent_off
          ? (discountObject.percent_off * chosenPlan.price) / 100
          : 0),
    );
    console.log(discountObject);
    setDiscount(discountObject);
  }

  useEffect(() => {
    onDiscountCodeInputBlur();
    if (chosenPlan && discount) {
      setTotalTableItems([
        {
          name: chosenPlan.name,
          price: chosenPlan.price,
          isDiscount: false,
        },
        {
          name: discountCode,
          price: discount.percent_off
            ? (discount.percent_off * chosenPlan.price) / 100
            : 0,
          isDiscount: true,
        },
      ]);

      setTotalPrice(
        chosenPlan.price -
          (discount.percent_off
            ? (discount.percent_off * chosenPlan.price) / 100
            : 0),
      );
    } else {
      setTotalTableItems(
        subOption !== undefined && chosenPlan
          ? [
              {
                name: chosenPlan.name,
                price: chosenPlan.price,
                isDiscount: false,
              },
            ]
          : [],
      );
      setTotalPrice(chosenPlan ? chosenPlan.price : 0);
    }
  }, [chosenPlan]);

  return chosenPlan && subOption !== undefined ? (
    <div
      className={cx(
        `mx-auto max-w-7xl md:px-6 transition-all ease-in duration-${animationDuration} opacity-0 translate-x-0`,
        !clicked && loaded ? 'opacity-100' : 'opacity-0 translate-x-20',
      )}
    >
      <div className="flex gap-5 flex-col-reverse xl:flex-row">
        <div className="p-8 shadow bg-white rounded-lg flex flex-col w-full xl:max-w-md h-fit">
          <div className="md:text-2xl text-xl mb-8">
            {stringsPricingPage.completeYourSignUp}
          </div>
          <div className="rounded-md bg-green-50 p-4 mb-4">
            <div className="flex">
              <div className="flex-shrink-0">
                <CheckCircleIcon
                  className="h-5 w-5 text-green-400"
                  aria-hidden="true"
                />
              </div>
              <div className="ml-3">
                <p className="text-sm font-medium text-green-800">
                  {trial ? (
                    <>
                      <span className="block mb-3">
                        {`${
                          stringsPricingPage.almostThere
                        } ${capitalizeFirstLetter(
                          getFirstNameFromFullName(userData?.name || ''),
                        )}! ${stringsPricingPage.youWillHaveTry}`}
                      </span>
                      <span>{stringsPricingPage.youCanCancelAvoidCharge}</span>
                    </>
                  ) : (
                    `${
                      stringsPricingPage.almostThere
                    } ${getFirstNameFromFullName(userData?.name || '')}! ${
                      stringsPricingPage.youCanCancel
                    }`
                  )}
                </p>
              </div>
            </div>
          </div>
          <div className="md:text-xl text-lg mt-2">
            {stringsPricingPage.discountCode}
          </div>
          <div className="max-w-[450px] flex items-center">
            <TextInput
              value={discountCode}
              onChange={(t: string) => setDiscountCode(t.toLowerCase())}
              onBlur={() => {
                console.log(couponMap[discountCode]);
                console.log(discountCode);
                onDiscountCodeInputBlur();
              }}
            />
            {discount ? (
              <div className="flex-shrink-0 mb-5 ml-2">
                <CheckCircleIcon
                  className="h-5 w-5 text-green-400"
                  aria-hidden="true"
                />
              </div>
            ) : null}
          </div>

          <div className="md:text-xl text-lg mt-2">
            {stringsPricingPage.paymentMethod}
          </div>
          <div className="">
            <CardPaymentForm
              currency={currency}
              userData={userData}
              price={chosenPlan.priceId} // get price id
              onSubmit={() => {
                setLoading(true);
              }}
              couponID={discount?.couponId}
              onFailure={(error: string) => {
                console.log(error);
                setError(error);
                setLoading(false);
              }}
              onSuccess={() => {
                if (onSuccess)
                  onSuccess(async () => {
                    if (userData?.id && viaToken) {
                      // await registerDiscountUsageByUser(
                      //   undefined,
                      //   tiers[subOption],
                      //   trialLength,
                      // );
                      if (discountCode)
                        await updateUserData(userData.id, {
                          couponCode: discountCode,
                        });
                      // @ts-ignore
                      rewardful('convert', { email: userData.email }); // Only convert if the user is a first timer
                    }
                    setLoading(false);
                  });
                else setLoading(false);
              }}
              subscription
            />
            {error ? (
              <div className="text-red-warning w-full">{error}</div>
            ) : null}
            {!trial ? (
              <div className="text-gray-500 mt-2">{`${(function returnFreq() {
                switch (chosenPlan?.intervalUnit) {
                  case 'month':
                    return stringsPricingPage.noteYouWillBeBilledMonthly;
                  case 'year':
                    return stringsPricingPage.noteYouWillBeBilledEveryYear;
                  case 'week':
                    return stringsPricingPage.noteYouWillBeBilledEvery4Weeks;
                  default:
                    return '';
                }
              })()}`}</div>
            ) : null}
          </div>
        </div>
        <div className="p-8 shadow bg-white rounded-lg flex flex-col w-full">
          <div className="md:text-2xl text-xl md:mb-14">
            {stringsPricingPage.summary}
          </div>
          <div className="md:block hidden">
            <LearningPlanOptionBox
              {...chosenPlan}
              price={
                chosenPlan?.price -
                (discount?.percent_off
                  ? (discount.percent_off * chosenPlan.price) / 100
                  : 0)
              }
              currency={currency}
              discount={
                discount?.percent_off
                  ? (discount.percent_off * chosenPlan.price) / 100
                  : 0
              }
              mostPopular={false}
              monthlyBilling
              hideSelect
            />
          </div>
          <TotalTable
            currency={currency}
            items={totalTableItems}
            totalPrice={totalPrice}
            realPrice={chosenPlan?.amount}
            nextBilling={
              trial
                ? moment(trialLength * dayInMili + new Date().valueOf()).format(
                    'MMMM D, YYYY',
                  )
                : chosenPlan?.nextBillingDate
            }
            trial={trial}
            notBilledToday={trial}
          />
          <div className="mt-2">
            <div
              className={cx(
                'text-blue-immigo ring-1 ring-inset ring-blue-immigo hover:ring-blue-immigo-lighter',
                'cursor-pointer mt-6 block rounded-md py-2 px-3 text-center text-sm font-semibold leading-6 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-immigo',
              )}
              onClick={() => {
                setClicked(true);
                trackBookingAnalytics(
                  'Clicked choose a different learning plan',
                  {
                    userID: userData?.id,
                    subOption: subOption,
                  },
                );
                setTimeout(() => {
                  setPage(8);
                }, animationDuration);
              }}
            >
              {stringsPricingPage.changeClassBooking}
            </div>
          </div>
        </div>
      </div>
    </div>
  ) : null;
}
