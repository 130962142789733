import { getDownloadURL, ref, uploadBytesResumable } from 'firebase/storage';
import {
  addDoc,
  arrayUnion,
  collection,
  doc,
  getDoc,
  getDocs,
  setDoc,
  updateDoc,
} from 'firebase/firestore';
import { createUserWithEmailAndPassword } from 'firebase/auth';
import { getAbsoluteTimeZoneDifferance } from '../util/standardization';
import { movToMp4Converter } from '../util/movtomp4converter';
import { initializeFirebase } from './configValues';
import { UserData } from '../types/user';
import { getUniqueArray } from '../util/data';
import { chronoMapToStreakCount } from '../util/helper';

import { getCustomerSubscriptionInformation } from '../api';
import momentTimezone from 'moment-timezone';
import { getUserBookings } from './subscription/bookings/bookings';
import { getFinalPriceGivenPriceIDAndCouponID } from '../config/stripe/price';
import { getUserNextHook } from '../api/users';
import { weekInMili } from '../usefuldata/mili';
import { getUserCurrentLearningPath } from '../api/learningPath';
import { getAllGuestPasses } from '../api/guestPass';
import { filterValidPasses } from '../util/guestPass';
import { getProduct } from '../api/payment';
import { GuestPass } from '../types/guestPass';

const { firestore: db, auth, storage } = initializeFirebase();

// Firestore Logics
// @TODO: review usage of callback
export const getUserByUID = async (uid: string, cb: Function) => {
  const userCollection = doc(db, 'users', uid);
  const userDocument = await getDoc(userCollection);

  if (!userDocument.exists()) {
    return;
  }

  const user = userDocument.data();
  user.id = uid;

  cb(user);
};

// Firestore Logics
// @TODO: review usage of callback
export const getUserWholeProfileByUID = async (uid: string, cb?: Function) => {
  let user;

  try {
    const userCollection = doc(db, 'users', uid);
    const userDocument = await getDoc(userCollection);

    if (!userDocument.exists()) {
      console.log(`${uid} Doesn't exist`);
      return;
    }

    user = userDocument.data();
    user.id = uid;

    const bookings = await getUserBookings(uid);
    const activeLearningPath = await getUserCurrentLearningPath(uid);
    const guestPasses = await getAllGuestPasses(uid);

    user.activeLearningPath = activeLearningPath;
    user.guestPasses = guestPasses;
    guestPasses.forEach((gp: GuestPass) => {
      if (gp.expires < new Date().valueOf() && gp.status === 'unused')
        gp.status = 'expired';
    });

    user.bookings = bookings || []; // Load user bookings
    if (user.customerID) {
      const subInfo = await getCustomerSubscriptionInformation(user.customerID);
      const nextHook = await getUserNextHook(uid, 'credit-update');

      if (subInfo?.plan?.product) {
        const product = await getProduct(subInfo?.plan?.product);
        if (product) user.product = product;
      }

      console.log(subInfo);
      user.subProductID = subInfo?.plan?.product;
      user.subStart = subInfo?.current_period_start * 1000;
      user.subEnd = subInfo?.current_period_end * 1000;
      user.servicePaused = subInfo?.pause_collection?.resumes_at
        ? subInfo?.current_period_start < subInfo?.pause_collection?.resumes_at
        : false;

      if (subInfo?.trial_end) user.trialEnd = subInfo?.trial_end * 1000;
      user.nextCreditUpdate = nextHook?.postAt ?? user.subEnd;
      if (user.nextCreditUpdate - new Date().valueOf() > 4 * weekInMili) {
        // if annual but has no hooks
        user.nextCreditUpdate = undefined; // don't show
      }
      user.subNextInvoice = getFinalPriceGivenPriceIDAndCouponID(
        subInfo?.plan?.id,
        subInfo?.discount?.coupon?.id,
        subInfo?.currency,
      );
    }
    // const onGoingCourses = await returnIncompleteCoursesWithUIDArrays(
    //   user.courses,
    // );

    // if (onGoingCourses) user.onGoingCourses = onGoingCourses; No longer doing courses

    cb?.(user);
  } catch (err) {
    console.log(err);
    console.log('getUserWholeProfileByUID : could not fetch user ' + uid);
    throw 'getUserWholeProfileByUID : could not fetch user';
  }

  return user;
};

export const getUserByUIDAsPromise = async (uid: string) => {
  const userCollection = doc(db, 'users', uid);
  const userDocument = await getDoc(userCollection);

  if (!userDocument.exists()) {
    return;
  }

  const user = userDocument.data();
  user.id = uid;

  return user;
};

export const returnUserByUID = async (uid: string) => {
  const userCollection = doc(db, 'users', uid);
  const userDocument = await getDoc(userCollection);

  if (!userDocument.exists()) {
    return;
  }

  const user = userDocument.data();
  user.id = uid;

  if (user?.chronoAttendanceMapByWeek)
    user.streak = chronoMapToStreakCount(user?.chronoAttendanceMapByWeek);

  return user;
};

export const returnAppByUID = async (uid: string) => {
  const applicationsDocument = doc(db, 'applications', uid);
  const appDoc = await getDoc(applicationsDocument);

  if (!appDoc.exists()) {
    return;
  }
  const application = appDoc.data();
  application.id = uid;

  return application;
};

export const returnAllReportCards = async () => {
  function countTotalPhraseUsage(phrases: Array<any>) {
    let sum = 0;
    phrases.forEach((phrase: any) => {
      sum += phrase['total'];
    });
    return sum;
  }
  function getPhraseUsageList(phrases: Array<any>) {
    let phraseList: Array<any> = [];
    phrases.forEach((phrase: any) => {
      if (phrase['total'] > 0)
        phraseList.push({
          phrase: phrase['phrase'],
          lastUsed: phrase['phrase'].lastUsed,
        });
    });
    return phraseList;
  }
  const reportCardCollection = collection(db, 'reportCard');
  const reportCardDocs = await getDocs(reportCardCollection);

  const result = await Promise.all(
    reportCardDocs.docs.map(async (doc) => {
      const phrasesToTrack = await collection(
        db,
        'reportCard',
        doc.id,
        'phrasesToTrack',
      );
      const phrases = await getDocs(phrasesToTrack);

      return {
        ...doc.data(),
        id: doc.id,
        phrases: phrases.docs.map((doc) => ({ ...doc.data() })),
        totalPhraseUsage: countTotalPhraseUsage(
          phrases.docs.map((doc) => ({ ...doc.data() })),
        ),
        phraseUsageList: getPhraseUsageList(
          phrases.docs.map((doc) => ({ ...doc.data() })),
        ),
      };
    }),
  );

  return result;
};

export const returnAllUsers = async () => {
  const usersCollection = collection(db, 'users');
  const usersDocs = await getDocs(usersCollection);

  const result = usersDocs.docs.map((doc) => ({
    ...doc.data(),
    id: doc.id,
  })) as UserData[];

  return result;
};

export const returnAllCourses = async () => {
  const coursesCollection = collection(db, 'courses');
  const coursesDocs = await getDocs(coursesCollection);

  const result = coursesDocs.docs.map((doc) => ({
    ...doc.data(),
    id: doc.id,
  }));

  return result;
};

export const returnCourseByUID = async (uid: string) => {
  const coursesCollection = doc(db, 'courses', uid);
  const courseDocument = await getDoc(coursesCollection);

  if (!courseDocument.exists()) {
    return;
  }

  const course = courseDocument.data();
  course.id = uid;

  return course;
};

export const returnCoursesWithUIDArrays = async (uids: Array<string>) => {
  const courses = await Promise.all(
    uids.map(async (uid: string) => {
      const course = await returnCourseByUID(uid);
      return course;
    }),
  );
  return courses;
};

export const returnIncompleteCoursesWithUIDArrays = async (
  uids: Array<string>,
) => {
  const courses = await returnCoursesWithUIDArrays(uids);
  const incomplete = courses.filter((course) => {
    return (
      course?.coursePeriod?.start >= new Date().valueOf() ||
      course?.coursePeriod?.end >= new Date().valueOf()
    );
  });
  return incomplete;
};

// @TODO review usage of callback
export const getCourseByUID = async (uid: string, cb: Function) => {
  const courseCollection = doc(db, 'courses', uid);
  const courseDocument = await getDoc(courseCollection);

  if (!courseDocument.exists()) {
    return;
  }

  const course = courseDocument.data();
  course.id = uid;

  cb(course);
};

// @TODO: review usage of callback
export const findCourseWithReadableID = async (
  readableID: string,
  cb: Function,
  onError: Function = () => {},
) => {
  const courseIDCollection = doc(db, 'courseID', readableID);
  try {
    const courseIDDocument = await getDoc(courseIDCollection);

    if (!courseIDDocument.exists()) {
      onError();
    }

    cb(courseIDDocument.data());
  } catch (e) {
    onError(e);
  }
};

export const enrollStudentInCourse = async (
  courseID: string,
  studentID: string,
  cb?: Function,
) => {
  const coursesRef = doc(db, 'courses', courseID);
  await updateDoc(coursesRef, {
    students: arrayUnion(studentID),
  });

  // Add student course to ID
  const userRef = doc(db, 'users', studentID);
  await updateDoc(userRef, {
    courses: arrayUnion(courseID),
  });

  const assignedPodIndices: Array<number> = await returnAvailablePodIndices(
    courseID,
  ); // Get available pod index

  console.log(assignedPodIndices);
  // Check if there's available pod
  if (assignedPodIndices.length === 0) {
    await addStudentToNewPod(courseID, studentID); // If no, create new pod
  } else {
    await assignStudentToPod(courseID, studentID, assignedPodIndices); // If yes, add student to that index
  }

  if (cb) cb();
};

// Add student to a pod group given a certain pod number. If pod index does not exist, return error.
// This is where we can match student to the best pod. (Matching criteria)
// Timezone proximity & age
// @TODO check callback
const assignStudentToPod = async (
  courseID: string,
  studentID: string,
  podIndices: Array<number>,
  cb?: Function,
) => {
  const courseDoc = await getDoc(doc(db, 'courses', courseID));
  const studentDoc = await getDoc(doc(db, 'users', studentID));
  const course = courseDoc.data();
  const student = studentDoc.data();

  if (!course || !course.podRoster) {
    console.log(
      'Error. assignStudentToPod : the course does not exist or the course does not have a podRoster.',
    );
    return;
  }

  const roster: Array<string> = course.podRoster;
  const podAttendance = course.podAttendance ? course.podAttendance : {};
  podAttendance[studentID] = {};

  let podIndex = podIndices[0]; // Default first index of available pod indices

  const canUseAvgAge =
    course.podAvgAge &&
    student &&
    student.age &&
    course.podAvgAge.length === course.podRoster.length; // Boolean value if average age is useable to assign students to better pod.

  // Given the podAvgTimeZoneOffsets
  if (
    course.podAvgTimeZoneOffsets &&
    course.podAvgTimeZoneOffsets.length === course.podRoster.length
  ) {
    const studentTimeOffset = new Date().getTimezoneOffset();
    let currentAgeOffset = canUseAvgAge
      ? Math.abs(course.podAvgAge[podIndex] - student.age)
      : 0; // Gets difference between age
    let currentOffset = getAbsoluteTimeZoneDifferance(
      studentTimeOffset,
      course.podAvgTimeZoneOffsets[podIndex],
    );
    for (let i = 0; i < podIndices.length; i++) {
      const currentPodIndex = podIndices[i];
      const newOffset = getAbsoluteTimeZoneDifferance(
        studentTimeOffset,
        course.podAvgTimeZoneOffsets[currentPodIndex],
      );
      const newAgeOffset = canUseAvgAge
        ? Math.abs(course.podAvgAge[podIndex] - student.age)
        : 0; // Gets difference between age

      // Update the assigning pod index if newer one is closer to the student's timezone
      if (currentOffset > newOffset || currentAgeOffset > newAgeOffset) {
        podIndex = currentPodIndex;
        currentOffset = newOffset;
        currentAgeOffset = newAgeOffset;
      }
    }
  } else {
    console.log(
      'Error. assignStudentToPod : This course does not have a valid podAvgTimeZoneOffsets array therefore, the student will be assigned randomly',
    );
  }

  if (roster.length < podIndex + 1) {
    console.log('Error. assignStudentToPod : No such pod at index ' + podIndex);
    return;
  }

  const podMembers: Array<string> = JSON.parse(roster[podIndex]);

  // Add new studentID if it doesn't exist already
  if (!podMembers.includes(studentID)) {
    podMembers.push(studentID);
  } else {
    console.log(
      'Error. addStudentToPod : Given studentID already exists in given array',
    );
    return;
  }

  roster[podIndex] = JSON.stringify(podMembers);

  // Update new roster (The whole thing..?)
  await updateDoc(doc(db, 'courses', courseID), {
    podRoster: roster,
    podAttendance: podAttendance,
  });

  await recalculatePodAvgTimeZoneOffset(courseID, podIndex);

  if (cb) {
    cb();
  }
};

// Add student to a pod group given a certain pod number. If pod index does not exist, return error.
const addStudentToNewPod = async (courseID: string, studentID: string) => {
  const courseRef = doc(db, 'courses', courseID);
  const courseDoc = await getDoc(courseRef);
  const course = await courseDoc.data();

  if (!course || !course.podRoster) {
    console.log(
      'Error. addStudentToPod : the course does not exist or the course does not have a podRoster.',
    );
    return;
  }

  const roster: Array<string> = course?.podRoster ?? {};
  const podAttendance = course.podAttendance ? course.podAttendance : {};
  podAttendance[studentID] = {};

  // Create new pod with the student
  roster.push(JSON.stringify([studentID]));

  await updateDoc(doc(db, 'courses', courseID), {
    podRoster: roster,
    podAttendance: podAttendance,
  });

  await recalculatePodAvgTimeZoneOffset(courseID, roster.length - 1);
};

// Add student to a pod group given a certain pod number. If pod index does not exist, return error.
const addStudentToPod = async (
  courseID: string,
  studentID: string,
  podIndex: number,
  cb?: Function,
) => {
  const courseDoc = await getDoc(doc(db, 'courses', courseID));
  const course = await courseDoc.data();

  if (!course || !course.podRoster) {
    console.log(
      'Error. addStudentToPod : the course does not exist or the course does not have a podRoster.',
    );
    return;
  }

  const roster: Array<string> = course.podRoster;

  if (roster.length < podIndex + 1) {
    roster.push('[]');
    podIndex = roster.length - 1; // reset pod index
    console.log(
      'Pod Index greater than roster length. Creating new pod.. ' + podIndex,
    );
  }

  const podMembers: Array<string> = JSON.parse(roster[podIndex]);

  // Add new studentID if it doesn't exist already
  if (!podMembers.includes(studentID)) {
    podMembers.push(studentID);
  } else {
    console.log(
      'Error. addStudentToPod : Given studentID already exists in given array',
    );
    return;
  }

  roster[podIndex] = JSON.stringify(podMembers);

  // Update new roster (The whole thing..?)
  await updateDoc(doc(db, 'courses', courseID), {
    podRoster: roster,
  });

  if (cb) cb();

  // Update the pod time offset
  await recalculatePodAvgTimeZoneOffset(courseID, podIndex);
};

// Remove student from a given pod
const removeStudentFromPod = async (
  courseID: string,
  studentID: string,
  cb?: Function,
) => {
  const courseDoc = await getDoc(doc(db, 'courses', courseID));
  const course = await courseDoc.data();

  if (!course || !course.podRoster) {
    console.log(
      'Error. removeStudentFromPod : the course does not exist or the course does not have a podRoster.',
    );
    return;
  }

  const roster: Array<string> = course.podRoster;
  let studentPodIndex = -1;

  for (let i = 0; i < roster.length; i++) {
    const currentPod = JSON.parse(roster[i]);

    if (currentPod.includes(studentID)) {
      const index = currentPod.indexOf(studentID);
      currentPod.splice(index, 1);
      roster[i] = JSON.stringify(currentPod); // Make it back into the right format
      studentPodIndex = i;
      break;
    }
  }

  await updateDoc(doc(db, 'courses', courseID), {
    podRoster: roster,
  });

  if (studentPodIndex >= 0)
    await recalculatePodAvgTimeZoneOffset(courseID, studentPodIndex);

  if (cb) cb();
};

// @TODO review async/await this fn didn't have async await in it but it's calling async fns
export const moveStudentToOtherPod = async (
  courseID: string,
  studentID: string,
  newPodIndex: number,
  cb?: Function,
) => {
  await removeStudentFromPod(courseID, studentID, () => {
    addStudentToPod(courseID, studentID, newPodIndex, cb);
  });
};

// This function will find an available pod in a given course pod roster (ie, less than 4 occupancy)
// Returns the pod index if found, -1 if not
const returnAvailablePodIndices = async (courseID: string) => {
  const courseOneRef = doc(db, 'courses', courseID);
  const course = await getDoc(courseOneRef);
  const courseData = course.data();
  const availablePodIndices: Array<number> = [];
  const timezoneOffset = new Date().getTimezoneOffset();

  if (courseData && courseData.podRoster) {
    for (let i = 0; i < courseData.podRoster.length; i++) {
      const currentPod = JSON.parse(courseData.podRoster[i]);
      console.log(
        getAbsoluteTimeZoneDifferance(
          timezoneOffset,
          courseData.podAvgTimeZoneOffsets[i],
        ),
      );
      if (
        currentPod.length < 4 &&
        getAbsoluteTimeZoneDifferance(
          timezoneOffset,
          courseData.podAvgTimeZoneOffsets[i],
        ) <= 180
      )
        availablePodIndices.push(i); // If there are less than 4 people in the pod and pod timediff is not greater than 3 hours
    }

    return availablePodIndices; // Could not find an available pod
  } else {
    console.log(
      'Error. returnAvailablePodNumber : courseData is undefined or podRoster does not exist.',
    );
    return availablePodIndices;
  }
};

// updates student timezone
export const updateStudentTimeZone = async (studentID: string) => {
  const timezoneOffset = new Date().getTimezoneOffset();
  const date = new Date();
  const dateAsString = date.toString().match(/\(([^\)]+)\)$/);
  let timeZoneString = undefined;
  if (dateAsString) {
    timeZoneString = dateAsString[1];
  }

  if (timeZoneString) {
    await updateDoc(doc(db, 'users', studentID), {
      timeZone: momentTimezone.tz.guess(),
      timeZoneOffset: timezoneOffset,
    });
  }
};

// update student info given a map
// Probably need to check the data... this is dangerous
export const updateStudentInformation = async (
  studentID: string,
  data: any,
) => {
  await updateDoc(doc(db, 'users', studentID), data);
};

//update avg timnezone offset when added a new student
// Updated : Change the function name to recalculatePodAvgInformation
const recalculatePodAvgTimeZoneOffset = async (
  courseID: string,
  podIndex: number,
) => {
  const courseRef = doc(db, 'courses', courseID);
  const course = await getDoc(courseRef);
  const courseData = await course.data();

  if (!courseData) {
    console.log(
      'Error. recalculatePodAvgTimeZoneOffset : Could not find the course with ID ' +
        courseID,
    );
    return;
  }

  if (!courseData.podRoster || courseData.podRoster.length === 0) {
    console.log(
      "Error. recalculatePodAvgTimeZoneOffset : The course does not have a podRoster or it's empty",
    );
    return;
  }

  const studentsIDs: Array<string> = JSON.parse(courseData.podRoster[podIndex]);

  const podMembers: Array<any> = await Promise.all(
    studentsIDs.map(async (studentID: string, index: number) => {
      const student = await returnUserByUID(studentID);
      if (student) {
        return student;
      }
    }),
  );

  let avgTimeOffset = 0;
  let avgAge = 0;

  if (podMembers.length > 0) {
    for (let i = 0; i < podMembers.length; i++) {
      if (podMembers[i].timeZoneOffset)
        avgTimeOffset += podMembers[i].timeZoneOffset;
      if (podMembers[i].age) avgAge += podMembers[i].age;
    }
    avgTimeOffset /= podMembers.length;
    avgAge /= podMembers.length;
  }

  const newPodAvgTimeZoneOffsets = courseData.podAvgTimeZoneOffsets
    ? courseData.podAvgTimeZoneOffsets
    : [];
  newPodAvgTimeZoneOffsets[podIndex] = avgTimeOffset;

  const newPodAvgAge = courseData.podAvgAge ? courseData.podAvgAge : [];
  newPodAvgAge[podIndex] = avgAge;

  await updateDoc(doc(db, 'courses', courseID), {
    podAvgTimeZoneOffsets: newPodAvgTimeZoneOffsets,
    podAvgAge: newPodAvgAge,
  });
};

export const handlePodRecordingUpload = async (
  files: Array<File>,
  courseID: string,
  podNumber: number,
  weekIndex: number,
  cb: Function,
  onProgress: Function,
  onError?: Function,
) => {
  if (files && files.length > 0) {
    const uploadedFiles = await Promise.all(
      files.map(async (file: any, index: number) => {
        file =
          file.type === 'video/quicktime'
            ? await movToMp4Converter(file, 'mp4')
            : file;
        const fileName =
          courseID +
          '_pod' +
          podNumber +
          '_week' +
          weekIndex +
          '_recording' +
          index;
        const uploadTask = uploadBytesResumable(
          ref(
            storage,
            '/pod_recordings/' +
              courseID +
              '/pod' +
              podNumber +
              '/week' +
              weekIndex +
              '/' +
              fileName,
          ),
          file,
        );

        uploadTask.on('state_changed', function (snapshot) {
          let progress =
            (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
          onProgress(progress);
        });
        const uploadedFile = await uploadTask;
        return uploadedFile; // snapshot
      }),
    );

    const uploadFileLinks = await Promise.all(
      uploadedFiles.map(async (snapShot) => {
        const link = await getDownloadURL(snapShot.ref);
        return link;
      }),
    );

    if (cb) {
      await updatePodRecordingLinks(
        podNumber - 1,
        courseID,
        weekIndex,
        uploadFileLinks,
      ); // Update the file links for the pod
      cb(uploadedFiles);
    }
  }
};

// Pod recording upload that allows multiple
export const handlePodRecordingUploadAllowMultiple = async (
  files: Array<File>,
  courseID: string,
  podNumber: number,
  weekIndex: number,
  cb: Function,
  onProgress: Function,
  onError?: Function,
) => {
  if (files && files.length > 0) {
    const course = await returnCourseByUID(courseID);
    const podRecordingLinkCount =
      course &&
      course.podRecordingLinks &&
      course.podRecordingLinks[podNumber - 1] &&
      course.podRecordingLinks[podNumber - 1][weekIndex]
        ? course.podRecordingLinks[podNumber - 1][weekIndex].length
        : 0; // See if there were any pod recordings uploaded previously. If yes, we don't want to overwrite it.

    const uploadedFiles = await Promise.all(
      files.map(async (file: any, index: number) => {
        file =
          file.type === 'video/quicktime'
            ? await movToMp4Converter(file, 'mp4')
            : file;
        const fileName =
          courseID +
          '_pod' +
          podNumber +
          '_week' +
          weekIndex +
          '_recording' +
          (index + podRecordingLinkCount) +
          '.webm';
        const uploadTask = uploadBytesResumable(
          ref(
            storage,
            '/pod_recordings/' +
              courseID +
              '/pod' +
              podNumber +
              '/week' +
              weekIndex +
              '/' +
              fileName,
          ),
          file,
        );

        uploadTask.on('state_changed', function (snapshot) {
          let progress =
            (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
          onProgress(progress);
        });
        const uploadedFile = await uploadTask;
        return uploadedFile; // snapshot
      }),
    );

    const uploadFileLinks = await Promise.all(
      uploadedFiles.map(async (snapShot) => {
        const link = await getDownloadURL(snapShot.ref);
        return link;
      }),
    );

    if (cb && course) {
      await updatePodRecordingLinksAllowMultiple(
        podNumber - 1,
        courseID,
        weekIndex,
        uploadFileLinks,
        course,
      ); // Update the file links for the pod
      cb(uploadFileLinks);
    }

    return uploadFileLinks;
  }
};

export const handleHomeworkUpload = async (
  files: Array<File>,
  courseID: string,
  studentID: string,
  weekNumber: number,
  cb: Function,
  onProgress: Function,
  onError?: Function,
) => {
  if (files && files.length > 0) {
    const uploadedFiles = await Promise.all(
      files.map(async (file: any, index: number) => {
        file =
          file.type === 'video/quicktime'
            ? await movToMp4Converter(file, 'mp4')
            : file;
        const fileName =
          courseID +
          '_' +
          studentID +
          '_week' +
          weekNumber +
          '_recording' +
          index;
        const uploadTask = uploadBytesResumable(
          ref(
            storage,
            '/homework/' +
              courseID +
              '/' +
              studentID +
              '/week' +
              weekNumber +
              '/' +
              fileName +
              file.type +
              fileName,
          ),
          file,
        );

        uploadTask.on('state_changed', function (snapshot) {
          let progress =
            (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
          onProgress(progress);
        });
        const uploadedFile = await uploadTask;
        return uploadedFile; // snapshot
      }),
    );

    const uploadFileLinks = await Promise.all(
      uploadedFiles.map(async (snapShot) => {
        const link = await getDownloadURL(snapShot.ref);
        return link;
      }),
    );

    if (cb) {
      await updateHomeworkLinks(
        studentID,
        courseID,
        weekNumber,
        uploadFileLinks,
      ); // Update the file links for the pod
      cb(uploadedFiles);
    }
  }
};

export const handleProfilePictureUpload = async (
  file: File,
  studentID: string,
  cb: Function,
  onProgress: Function,
  onError?: Function,
) => {
  if (file) {
    const fileName = studentID + '_profile_picture';
    const uploadTask = uploadBytesResumable(
      ref(storage, '/profile_pictures/' + fileName),
      file,
    );

    uploadTask.on('state_changed', function (snapshot) {
      let progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
      onProgress(progress);
    });

    const uploadedFile = await uploadTask;
    const uploadFileLink = await getDownloadURL(uploadedFile.ref);

    if (cb) {
      await updateProfilePictureLink(studentID, uploadFileLink, cb); // Update the file links for the pod
    }
  }
};

// Week Index = week1 = 0
export const updatePodAttendance = async (
  studentID: string,
  courseID: string,
  weekIndex: number,
  attended: boolean,
) => {
  const course = await returnCourseByUID(courseID);

  // Make sure that student is validly in the course attendance list
  if (course) {
    const field = ['podAttendance', studentID, weekIndex].join('.');
    const updateMap: any = {};
    updateMap[field] = attended;

    await updateDoc(doc(db, 'courses', courseID), updateMap);
  }
};

// Week Index = week1 = 0
export const updatePodRecordingLinks = async (
  podNumber: number,
  courseID: string,
  weekIndex: number,
  links: Array<string>,
) => {
  const course = await returnCourseByUID(courseID);

  // Make sure that student is validly in the course attendance list
  if (course) {
    const field = ['podRecordingLinks', podNumber, weekIndex].join('.');
    const updateMap: any = {};
    updateMap[field] = links;

    await updateDoc(doc(db, 'courses', courseID), updateMap);
  }
};

export const updateStudentIdle = async (studentID: string, isIdle: boolean) => {
  updateStudentInformation(studentID, {
    isIdle: isIdle,
  });
};

// Pod recording upload that does not overwrite existing recoring (Implemented temporarily to allow students to upload multiple pod meeting recordings in a given week)
export const updatePodRecordingLinksAllowMultiple = async (
  podIndex: number,
  courseID: string,
  weekIndex: number,
  links: Array<string>,
  courseDoc: any,
) => {
  // Make sure that student is validly in the course attendance list
  if (courseDoc) {
    const podRecordingLinks: Array<string> =
      courseDoc.podRecordingLinks &&
      courseDoc.podRecordingLinks[podIndex] &&
      courseDoc.podRecordingLinks[podIndex][weekIndex]
        ? courseDoc.podRecordingLinks[podIndex][weekIndex]
        : [];

    console.log(podRecordingLinks);
    console.log(podRecordingLinks.concat(links));
    const field: string = ['podRecordingLinks', podIndex, weekIndex].join('.');
    const updateMap: any = {};
    updateMap[field] = podRecordingLinks.concat(links); // Merge recording list

    await updateDoc(doc(db, 'courses', courseID), updateMap);
  }
};

// Week Index = week1 = 0
export const updateHomeworkLinks = async (
  studentID: string,
  courseID: string,
  weekIndex: number,
  links: Array<string>,
) => {
  const course = await returnCourseByUID(courseID);

  // Make sure that student is validly in the course attendance list
  if (course) {
    let homeworkLinks = course.homeworkLinks;
    if (homeworkLinks === undefined) {
      homeworkLinks = {}; // Initialize the map if need be
    }
    if (homeworkLinks[studentID] === undefined) {
      homeworkLinks[studentID] = {};
    }

    homeworkLinks[studentID][weekIndex] = links;

    await updateDoc(doc(db, 'courses', courseID), {
      homeworkLinks: homeworkLinks,
    });
  }
};

export const updateProfilePictureLink = async (
  studentID: string,
  link: string,
  cb?: Function,
) => {
  await updateDoc(doc(db, 'users', studentID), { profilePictureLink: link });

  if (cb) cb(link);
};

export const updateUserCustomerID = async (
  studentID: string,
  customerID: string,
  cb?: Function,
) => {
  await updateDoc(doc(db, 'users', studentID), { customerID: customerID });
};

export const updateUserLoggedIn = async (studentID: string, cb?: Function) => {
  await updateDoc(doc(db, 'users', studentID), {
    lastAuth: new Date().valueOf(),
  });
};

export const updateUserWordCheckList = async (
  newWordList: any,
  studentID: string,
  courseID: string,
) => {
  const course = await returnCourseByUID(courseID);

  // Make sure that student is validly in the course attendance list
  if (course) {
    let wordCheckList = course.wordCheckList;
    if (wordCheckList === undefined) {
      wordCheckList = {}; // Initialize the map if need be
    }
    if (wordCheckList[studentID] === undefined) {
      wordCheckList[studentID] = {};
    }

    wordCheckList[studentID] = newWordList;

    await updateDoc(doc(db, 'courses', courseID), {
      wordCheckList: wordCheckList,
    });
  }
};

export const addWordCommitmentToCourse = async (
  studentID: string,
  courseID: string,
  words: string[],
  cb?: Function,
) => {
  const course = await returnCourseByUID(courseID);

  // Make sure that student is validly in the course attendance list
  if (course) {
    let wordCheckList = course.wordCheckList;
    if (wordCheckList === undefined) {
      wordCheckList = {}; // Initialize the map if need be
    }

    const wordMap: any = {};

    // create word map
    words.forEach((word: string) => {
      wordMap[word] = false;
    });

    wordCheckList[studentID] = wordMap;

    await updateDoc(doc(db, 'courses', courseID), {
      wordCheckList: wordCheckList,
    });

    if (cb) cb();
  }
};

const addApplicationToCourse = async (
  studentID: string,
  courseID: string,
  applicationID: string,
) => {
  const course = await returnCourseByUID(courseID);

  // Make sure that student is validly in the course attendance list
  if (course) {
    let applicationIDs = course.applications;
    if (applicationIDs === undefined) {
      applicationIDs = {}; // Initialize the map if need be
    }

    applicationIDs[studentID] = applicationID;

    await updateDoc(doc(db, 'courses', courseID), {
      applications: applicationIDs,
    });
  }
};

const addApplicationToUser = async (
  studentID: string,
  courseID: string,
  applicationID: string,
) => {
  const user = await returnUserByUID(studentID);

  // Make sure that student is validly in the course attendance list
  if (user) {
    let applicationIDs = user.applications;
    if (applicationIDs === undefined) {
      applicationIDs = {}; // Initialize the map if need be
    }

    applicationIDs[courseID] = applicationID;

    await updateDoc(doc(db, 'users', studentID), {
      applications: applicationIDs,
    });
  }
};

// Create application document
export const createApplicationDocument = async (
  studentID: string,
  courseID: string,
  paid: boolean,
  data: any,
  distributorType?: string,
  referralCode?: string,
) => {
  const applicationData = {
    ...data,
    studentID: studentID,
    courseID: courseID,
    paid: paid,
    createdAt: Date.now(),
  };

  console.log('creating one');

  if (distributorType) applicationData.distributorType = distributorType;
  if (referralCode) applicationData.referralCode = referralCode;

  const userUpdateData: any = {
    nationality: data.nationality,
  };

  if (data.livingAbroad !== undefined)
    userUpdateData.livingAbroad = data.livingAbroad;
  if (data.howLongLivedAbroad !== undefined)
    userUpdateData.howLongLivedAbroad = data.howLongLivedAbroad;
  if (data.planOnLivingAbroad !== undefined)
    userUpdateData.planOnLivingAbroad = data.planOnLivingAbroad;
  if (data.whenPlannedLivingAbroad !== undefined)
    userUpdateData.whenPlannedLivingAbroad = data.whenPlannedLivingAbroad;
  if (data.whichCountryPlanToMove !== undefined)
    userUpdateData.whichCountryPlanToMove = data.whichCountryPlanToMove;
  if (data.preparingForExam !== undefined)
    userUpdateData.preparingForExam = data.preparingForExam;
  if (data.preparingForExam !== undefined)
    userUpdateData.cefrLevel = data.cefrLevel;
  if (data.basedCountry === undefined)
    userUpdateData.basedCountry = data.nationality;
  else userUpdateData.basedCountry = data.basedCountry;

  // add country data to user
  if (data.nationality) {
    await updateDoc(doc(db, 'users', studentID), userUpdateData);
  }

  const docRef = await addDoc(collection(db, 'applications'), applicationData);

  const applicationID = docRef.id;
  await addApplicationToCourse(studentID, courseID, applicationID);
  await addApplicationToUser(studentID, courseID, applicationID); // uncomment this after testing

  return applicationID;
};

// Update application document
export const updateApplicationDocument = async (
  studentID: string,
  appID: string,
  data: any,
  discountCode?: string,
) => {
  const userUpdateData: any = {
    nationality: data.nationality,
  };

  if (data.livingAbroad !== undefined)
    userUpdateData.livingAbroad = data.livingAbroad;
  if (data.howLongLivedAbroad !== undefined)
    userUpdateData.howLongLivedAbroad = data.howLongLivedAbroad;
  if (data.planOnLivingAbroad !== undefined)
    userUpdateData.planOnLivingAbroad = data.planOnLivingAbroad;
  if (data.whenPlannedLivingAbroad !== undefined)
    userUpdateData.whenPlannedLivingAbroad = data.whenPlannedLivingAbroad;
  if (data.whichCountryPlanToMove !== undefined)
    userUpdateData.whichCountryPlanToMove = data.whichCountryPlanToMove;
  if (data.preparingForExam !== undefined)
    userUpdateData.preparingForExam = data.preparingForExam;
  if (data.preparingForExam !== undefined)
    userUpdateData.cefrLevel = data.cefrLevel;
  if (data.basedCountry === undefined)
    userUpdateData.basedCountry = data.nationality;
  else userUpdateData.basedCountry = data.basedCountry;
  if (discountCode) userUpdateData.discountCode = discountCode;

  // add country data to user
  if (data.nationality) {
    await updateDoc(doc(db, 'users', studentID), userUpdateData);
  }

  await updateDoc(doc(db, 'applications', appID), { ...data });
};

// Add purchased course under user
export const addCourseOnUser = async (courseID: string, studentID: any) => {
  // Add student course to ID
  await updateDoc(doc(db, 'users', studentID), {
    courses: arrayUnion(courseID),
  });
};

//@params
//id : String of userID in firebase
//amount : integer neg/pos in $USD amount
export async function updateCreditToUser(studentID: string, amount: number) {
  const userData = await returnUserByUID(studentID);

  if (userData) {
    let credit = userData.credit !== undefined ? userData.credit : 0;
    credit += amount; // Add neg/pos credit
    await updateDoc(doc(db, 'users', studentID), {
      credit: credit,
    });
  }
}

//@params
//id : String of userID in firebase
//amount : integer neg/pos in $USD amount
export async function overrideCreditToUser(studentID: string, amount: number) {
  await updateDoc(doc(db, 'users', studentID), {
    credit: amount,
  });
}

/* Referral Logic */
export async function returnReferralData(code: string) {
  const referralCode = await getDoc(doc(db, 'referralCodes', code));
  const referralCodeDoc = referralCode.data();
  if (referralCodeDoc) referralCodeDoc.code = referralCode.id;
  return referralCodeDoc;
}

export async function returnReferrerDataWithCode(code: string) {
  const referralCode = await getDoc(doc(db, 'referralCodes', code));
  const referralCodeDoc = await referralCode.data();
  const referrerDoc =
    referralCodeDoc && referralCodeDoc.userID
      ? await returnUserByUID(referralCodeDoc.userID)
      : undefined;

  return referrerDoc;
}

// verify if the code exists to begin with
export async function verifyReferralCode(code?: string): Promise<boolean> {
  if (!code) return false;
  try {
    const referralCode = await getDoc(
      doc(db, 'referralCodes', code.toUpperCase()),
    );

    return referralCode.exists();
  } catch (e) {
    return false;
  }
}

// Tracking user referral
export async function trackUserReferral(
  referralCode: string,
  referredUserID: string,
  referredCourseID?: string,
) {
  const referralData = await returnReferralData(referralCode);

  // Referral code exists
  if (referralData) {
    let referredStudentIDList = referralData.referredStudentIDList;
    let referralTimeMap = referralData.referralTimeMap;

    if (!referredStudentIDList) referredStudentIDList = [];
    if (!referralTimeMap) referralTimeMap = {};

    // Update referral data
    referredStudentIDList.push(referredUserID);
    referralTimeMap[referredUserID] = {
      createdAt: new Date().valueOf(),
    };

    if (referredCourseID)
      referralTimeMap[referredUserID].referredCourseID = referredCourseID;

    // update new referral information
    await updateDoc(doc(db, 'referralCodes', referralCode), {
      referredStudentIDList: referredStudentIDList,
      referralTimeMap: referralTimeMap,
    });

    // Track that this user was referred by the user that owns this referral code
    if (referralData.userID) {
      await updateDoc(doc(db, 'users', referredUserID), {
        referredBy: referralData.userID,
      });
    }
  }
}

// Update a pod roster based on the matching
// Receives array of student data with podNumber field populated ordered by podNumber
export async function updatePodRosterWithNewFormation(
  courseID: string,
  rosterData: any,
) {
  const formattedRoster = [];
  let currentPodNumber = 1;
  let currentPod: Array<string> = [];

  for (let i = 0; i < rosterData.length; i++) {
    const currentStudent = rosterData[i];
    if (currentStudent.podNumber !== currentPodNumber) {
      formattedRoster.push(JSON.stringify(currentPod));
      currentPod = [];
      currentPodNumber = currentStudent.podNumber; // update current pod number
    }
    currentPod.push(currentStudent.id);
  }

  if (currentPod.length > 0) {
    formattedRoster.push(JSON.stringify(currentPod)); // push last pod
  }

  console.log(formattedRoster);

  await updateDoc(doc(db, 'courses', courseID), {
    podRoster: formattedRoster,
  });
}

/*
  Meeting stuff
*/

//@params
// attendants : Array of userIDs
// courseID : string of course document ID
// weekIndex : (integer) Index of the week (ie 1st week of the course == 0)
// podIndex : (integer) podIndex (ie pod 1 === 0)
// returns doc id
export const createMeetingSessionDoc = async (
  attendants: string[],
  leader: string,
  courseID?: string,
  weekIndex?: number,
  podIndex?: number,
  topic?: string,
  createdBy?: string,
) => {
  attendants = getUniqueArray(attendants); // Get unique attendants
  const userTimeData: any = {};
  attendants.forEach((userID: string) => {
    userTimeData[userID] = 0;
  });

  const meetingSession: any = {
    attendants: attendants,
    leader: leader,
    createdAt: new Date().valueOf(),
    userTimeData: userTimeData,
  };

  if (courseID) meetingSession.courseID = courseID;
  if (weekIndex !== undefined) meetingSession.weekIndex = weekIndex;
  if (podIndex !== undefined) meetingSession.podIndex = podIndex;
  if (topic !== undefined) meetingSession.topic = topic;
  if (createdBy !== undefined) {
    meetingSession.createdBy = createdBy;
    meetingSession.personal = true;
  }

  const meetingDocumentSnapshot = await addDoc(
    collection(db, 'meetingSessions'),
    {
      ...meetingSession,
    },
  );

  if (courseID && podIndex !== undefined)
    await addMeetingSessionToCourse(
      courseID,
      podIndex,
      meetingDocumentSnapshot.id,
    );
  attendants.forEach(async (attendant: string) => {
    await addMeetingSessionToTheUser(meetingDocumentSnapshot.id, attendant);
  });

  return meetingDocumentSnapshot.id;
};

export const addMeetingSessionToTheUser = async (
  sessionDocID: string,
  studentID: string,
) => {
  const meetingSessionSnapshot = await getDoc(
    doc(db, 'meetingSessions', sessionDocID),
  );

  const userDoc = await returnUserByUID(studentID);
  const meetingSessionDoc: any = meetingSessionSnapshot.data();

  if (meetingSessionDoc.attendants) {
    if (!meetingSessionDoc.attendants.includes(studentID))
      meetingSessionDoc.attendants.push(studentID);
  } else {
    meetingSessionDoc.attendants = [studentID];
  }

  if (meetingSessionDoc.userTimeData) {
    if (meetingSessionDoc.userTimeData[studentID] === undefined)
      meetingSessionDoc.userTimeData[studentID] = 0;
  } else {
    meetingSessionDoc.userTimeData = {
      studentID: 0,
    };
  }

  if (userDoc) {
    if (!userDoc.meetingSessionMap) {
      userDoc.meetingSessionMap = {};
    }
    if (!userDoc.meetingSessionMap[meetingSessionDoc.courseID]) {
      userDoc.meetingSessionMap[meetingSessionDoc.courseID] = [];
    }
    if (
      !userDoc.meetingSessionMap[meetingSessionDoc.courseID].includes(
        sessionDocID,
      )
    )
      userDoc.meetingSessionMap[meetingSessionDoc.courseID].push(sessionDocID);
    else {
      return { status: 'failed', message: 'user already in the document' }; // This user was already added to the document
    }

    await updateDoc(doc(db, 'users', studentID), {
      meetingSessionMap: userDoc.meetingSessionMap,
    });
  }

  await updateDoc(doc(db, 'meetingSessions', sessionDocID), {
    attendants: meetingSessionDoc.attendants,
    userTimeData: meetingSessionDoc.userTimeData,
  });

  return {
    status: 'success',
    message: 'user successfully added to the meeting document',
  };
};

export const updateUserSessionTime = async (
  sessionDocID: string,
  studentID: string,
) => {
  const meetingSessionSnapshot = await getDoc(
    doc(db, 'meetingSessions', sessionDocID),
  );
  const meetingSessionDoc: any = meetingSessionSnapshot.data();

  if (meetingSessionDoc.userTimeData) {
    if (meetingSessionDoc.userTimeData[studentID] === undefined)
      meetingSessionDoc.userTimeData[studentID] = 0;
  } else {
    meetingSessionDoc.userTimeData = {
      studentID: 0,
    };
  }

  const field = ['userTimeData', studentID].join('.');
  const updateMap: any = {};
  updateMap[field] = meetingSessionDoc.userTimeData[studentID] + 1; // Add one minute

  await updateDoc(doc(db, 'meetingSessions', sessionDocID), updateMap);
};

export const updateUserScript = async (
  sessionDocID: string,
  studentID: string,
  script: string,
) => {
  const meetingSessionSnapshot = await getDoc(
    doc(db, 'meetingSessions', sessionDocID),
  );
  const meetingSessionDoc: any = meetingSessionSnapshot.data();

  if (meetingSessionDoc.script) {
    if (meetingSessionDoc.script[studentID] === undefined)
      meetingSessionDoc.script[studentID] = '';
  } else {
    meetingSessionDoc.script = {
      studentID: '',
    };
  }

  const field = ['script', studentID].join('.');
  const updateMap: any = {};
  updateMap[field] = meetingSessionDoc.script[studentID] + ' ' + script;

  await updateDoc(doc(db, 'meetingSessions', sessionDocID), updateMap);
};

export const updateMeetingSessionRecordingLink = async (
  sessionDocID: string,
  recordingLink: string,
) => {
  await updateDoc(doc(db, 'meetingSessions', sessionDocID), {
    recordingLink: recordingLink,
  });
};

export const addMeetingSessionToCourse = async (
  courseID: string,
  podIndex: number,
  meetingSessionDocID: string,
) => {
  const course = await returnCourseByUID(courseID);

  // Make sure that student is validly in the course attendance list
  if (course) {
    let meetingSessionMap = course.meetingSessionMap;
    if (meetingSessionMap === undefined) {
      meetingSessionMap = {}; // Initialize the map if need be
    }

    if (!meetingSessionMap[podIndex]) {
      meetingSessionMap[podIndex] = [];
    }

    if (!meetingSessionMap[podIndex].includes(meetingSessionDocID)) {
      meetingSessionMap[podIndex] = [
        ...meetingSessionMap[podIndex],
        meetingSessionDocID,
      ];
    }

    await updateDoc(doc(db, 'courses', courseID), {
      meetingSessionMap: meetingSessionMap,
    });
  }
};

export const returnMeetingSessionDocByUID = async (uid: string) => {
  const meetingSession = await getDoc(doc(db, 'meetingSessions', uid));
  const meetingSessionDoc = await meetingSession.data();
  if (meetingSessionDoc) meetingSessionDoc.id = meetingSession.id;
  return meetingSessionDoc;
};
