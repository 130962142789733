import { useEffect, useRef, useState } from 'react';
import { BackgroundRecorder } from '../../components/backgroundProcess/recorder';
import { BackgroundTranscriber } from '../../components/backgroundProcess/transcriber';
import { PrimaryButton } from '../../components/buttons/primary';
import { SpeechMaticTranscriptResult } from '../../types/speechmatic';
import {
  returnFormattedStringsGivenResultsArray,
  returnFormattedStringsGivenResultsArrayWithMultipleSpeakers,
} from '../../util/speechmaticDataHandler';
import { MicrophoneIcon, PauseIcon } from '@heroicons/react/20/solid';
import cx from 'classix';

export const TranscriberCorrector = (props: {
  onTranscribe?: Function;
  onPartialTranscript?: Function;
  onTextResult?: Function;
  pauseTranscriber?: boolean;
  disableButton?: boolean;
}) => {
  const [recorderReady, setRecorderReady] = useState(false);
  const [isRecording, setIsRecording] = useState(false);
  const [readyToInitializeRecorder, setReadyToInitializeRecorder] =
    useState(false);
  const [transcriberReady, setTranscriberReady] = useState(false);
  const [isTranscribing, setIsTranscribing] = useState(false); // State transcribing by default. We will wait for the transcriber before recording.

  const {
    onPartialTranscript,
    onTextResult,
    pauseTranscriber,
    disableButton,
    onTranscribe,
  } = props;

  const socketRef = useRef<undefined | WebSocket>();

  async function onTranscriberReady(socketClient: WebSocket | undefined) {
    socketRef.current = socketClient; // Set the socket client
    setTranscriberReady(true);
  }

  async function onIncomingText(
    currentText: string,
    incomingText: string,
    onProcessFinished: Function,
  ) {
    try {
      // Need to include question mark
      const punctReg = new RegExp(/[?.!,]/);
      let originalText = currentText;
      let newTranscript = incomingText[0]?.match(punctReg)
        ? originalText.trim() + incomingText
        : originalText + incomingText;

      console.log(currentText);
      console.log(incomingText);

      onProcessFinished(newTranscript);
    } catch (err) {
      console.log(err);
    }
  }

  async function onIncomingResults(
    incomingResults: Array<SpeechMaticTranscriptResult>,
    formattedResults?: Array<string>,
  ) {
    try {
      const formattedTranscriptArray: Array<string> =
        formattedResults ??
        returnFormattedStringsGivenResultsArray(incomingResults);

      const formattedTranscriptArrayOld: any[] = [];

      let newSentences = formattedTranscriptArray.filter(
        (x) =>
          !formattedTranscriptArrayOld
            .map((s: string) => {
              return s.split('-').splice(1).join('-'); // Get rid of the timestamp to standardize
            })
            .includes(x), // This would break if somehow timestamp & content are exactly the same
      );
      const newSentencesWithTimeStamp = newSentences.map((s: string) => {
        return `${new Date().valueOf()}-${s}`; // Add current time this sentence is being added
      });

      onTextResult?.(newSentencesWithTimeStamp);
      console.log(newSentencesWithTimeStamp);
    } catch (err) {}
  }

  useEffect(() => {
    if (isRecording) {
      setIsTranscribing(true);
    }
  }, [isRecording]);

  useEffect(() => {
    if (pauseTranscriber) {
      setIsRecording(false);
      setIsTranscribing(false);
    }
  }, [pauseTranscriber]);

  return (
    <div className="flex justify-center items-center">
      <BackgroundRecorder
        isRecording={isRecording}
        isReadyToInitializeRecorder={readyToInitializeRecorder}
        onRecorderReady={async (tracks: Array<MediaStreamTrack>) => {
          let deviceConnected = '';
          tracks.forEach((track) => (deviceConnected += `${track.label} |`));
          console.log(`Recorder ready: device connected ${deviceConnected}`);
          setRecorderReady(true);
          setIsRecording(true);
        }}
        onStartRecording={async () => {
          console.log('start recording');
          setIsRecording(true);
        }}
        onDataAvailable={(data: Blob) => {
          if (socketRef?.current) {
            socketRef?.current?.send(data);
          }
          console.log(data);
        }}
      />
      <BackgroundTranscriber
        isTranscribing={isTranscribing}
        onConnectionCreated={onTranscriberReady}
        onIncomingText={onIncomingText}
        onIncomingResults={onIncomingResults}
        onError={() => {}}
        isRecording={isRecording}
        onPartialTranscript={onPartialTranscript}
      />
      <div
        className={cx(
          'rounded-full border-4 w-24 h-24 flex items-center justify-center  mt-10',
          disableButton
            ? 'border-gray-500 text-gray-500'
            : 'cursor-pointer border-red-500 text-red-500 hover:border-red-300 hover:text-red-300',
        )}
        onClick={() => {
          if (!disableButton) {
            if (!isTranscribing) {
              setReadyToInitializeRecorder(true);
              onTranscribe?.();
            } else {
              setIsTranscribing(false);
              setIsRecording(false);
            }
          }
        }}
      >
        {isTranscribing ? (
          <PauseIcon className="w-14 h-14 p-2" />
        ) : (
          <MicrophoneIcon className="w-14 h-14 p-2" />
        )}
      </div>
    </div>
  );
};
