import { JourneyButtonObject } from '../../types/journey';
import { JourneyButton } from './journeyButton';

export const JourneyButtonContainers = (props: {
  options: Array<JourneyButtonObject>;
}) => {
  const { options } = props;

  return (
    <div className="grid grid-cols-1 gap-8 md:mt-12 mt-6 md:col-span-2 col-span-3">
      {options.map((option) => {
        return <JourneyButton {...option} key={option.title} />;
      })}
      ∂
    </div>
  );
};
