import { RadioGroup } from '@headlessui/react';
import { cx } from 'classix';
import { useEffect, useState } from 'react';
import {
  classesSubscriptionTiers,
  classesSubscriptionTiersYearly,
  couponMap,
} from '../../../config/stripe';
import { trackBookingAnalytics } from '../../../features/Booking/analytics';
import { PricingOptionBox } from '../../../features/Booking/pricingOptionBox';
import { UserData } from '../../../types/user';
import { frequenciesPricing, FrequencyPricng } from '../PricingPage';
import { stringsPricingPage } from '../../../util/localization';
import DropDownFancy from '../../../components/forms/dropDownFancy/dropDownFancy';
import { supportedCurrencies } from '../../../util/currency';
import { getAllLearningPlans } from '../../../api/learningPlans';
import { LearningPlanOptionBox } from '../../../components/productBoxes/learningPlanOptionBox';
import { getDiscountCode, getPriceDiscount } from '../../../api/discountCodes';
import { ArrowRightIcon } from '@heroicons/react/24/outline';
import { JourneyButtonContainers } from '../../../components/journey/journeyButtonContainer';
import { getCityThemedClassesThisWeek } from '../../../api/bookings';
import { BookingData } from '../../../firebase/subscription/subscription';
import { getDestination } from '../../../api/destinations';

const animationDuration = 300;

export function JourneyPage(props: {
  page: number;
  setPage: Function;
  userData: UserData;
  onJourneyChosen: Function;
}) {
  const search = new URLSearchParams(window.location.search);
  const viaToken = search.get('via');

  const [clicked, setClicked] = useState(false);
  const [loaded, setLoaded] = useState(false);
  const [cities, setCities] = useState<any[] | undefined>();

  const { page, setPage, userData, onJourneyChosen } = props;

  const currencyOptions = (
    viaToken && couponMap[viaToken]?.currency
      ? couponMap[viaToken]?.currency
      : supportedCurrencies
  ).map((c: string) => {
    return { id: c, name: c.toLocaleUpperCase() };
  });

  async function onLoad() {
    const destinationClasses = await getCityThemedClassesThisWeek();
    const destinationTags: string[] = [];
    const destinationObjects: any[] = [];

    await Promise.all(
      destinationClasses.map(async (c: BookingData) => {
        if (c.themeLocation && !destinationTags.includes(c.themeLocation)) {
          destinationTags.push(c.themeLocation);
          const destination = await getDestination(c.themeLocation);
          if (destination) destinationObjects.push(destination);
        }
      }),
    );

    setCities(destinationObjects);
    console.log(destinationObjects);
  }

  useEffect(() => {
    setLoaded(true);
    onLoad();
  }, []);

  return (
    <div
      className={cx(
        `mx-auto max-w-7xl md:px-6 xl:px-8 transition-all ease-in duration-${animationDuration} translate-x-0`,
        !clicked && loaded ? 'opacity-100' : 'opacity-0 -translate-x-20',
      )}
    >
      <div className="mx-auto max-w-4xl text-center">
        <p className="mt-2 md:text-3xl text-xl font-bold tracking-tight text-gray-900 dark:text-white">
          {stringsPricingPage.chooseYourJourney}
        </p>
      </div>
      <p className="mx-auto md:mt-6 max-w-2xl text-center md:text-lg text-md leading-8 text-gray-600 dark:text-gray-300">
        {stringsPricingPage.journeyFrom}
      </p>
      <div>
        {cities ? (
          <JourneyButtonContainers
            options={cities.map((city) => {
              return {
                title: city.name,
                description: 'hello',
                imageUrl: city.imageUrl,
                onClick: () => {
                  console.log(city.id);
                  onJourneyChosen(city.id);
                  trackBookingAnalytics('Selected a journey on journey page', {
                    cityTag: city.id,
                  });
                },
              };
            })}
          />
        ) : null}
      </div>
    </div>
  );
}
