import {
  ArrowRightIcon,
  ChatBubbleLeftEllipsisIcon,
} from '@heroicons/react/24/outline';
import { RadialProgress } from '../../progress/radialProgress';
import cx from 'classix';
import { ForwardRefExoticComponent, SVGProps } from 'react';

type LearningPathCardColorTheme =
  | 'green'
  | 'blue'
  | 'yellow'
  | 'orange'
  | 'red';

type LearningPathThemeToColorMap = {
  [key in LearningPathCardColorTheme]: string;
};

const mainThemeToColorMap: LearningPathThemeToColorMap = {
  green: '#22c55e',
  blue: '#3b82f6',
  yellow: '#eab308',
  red: '#ef4444',
  orange: '#f97316',
};

const subThemeToColorMap: LearningPathThemeToColorMap = {
  green: '#f0fdf4',
  blue: '#dbeafe',
  yellow: '#fef9c3',
  red: '#fee2e2',
  orange: '#ffedd5',
};

const thirdThemeToColorMap: LearningPathThemeToColorMap = {
  green: '#86efac',
  blue: '#93c5fd',
  yellow: '#fde047',
  red: '#fca5a5',
  orange: '#fdba74',
};

export const LearningPathRequirementCard = (props: {
  colorTheme: LearningPathCardColorTheme;
  title: string;
  description?: string;
  completed?: number;
  needed?: number;
  Icon: ForwardRefExoticComponent<Pick<SVGProps<SVGSVGElement>, any>>;
  onSuggestionClick?: Function;
}) => {
  const {
    colorTheme,
    Icon,
    title,
    description,
    completed,
    needed,
    onSuggestionClick,
  } = props;

  return (
    <div
      className={cx('rounded-md')}
      style={{
        backgroundColor: mainThemeToColorMap[colorTheme],
      }}
    >
      <div className="p-4">
        <div className="flex">
          <div
            className={cx('p-3 rounded-md max-h-14 max-w-14')}
            style={{
              backgroundColor: subThemeToColorMap[colorTheme],
            }}
          >
            <Icon
              className={cx('w-8 h-8')}
              style={{
                color: mainThemeToColorMap[colorTheme],
              }}
            />
          </div>
          <div className="ml-4 w-full flex flex-col justify-center">
            <div
              className={cx('font-semibold text-2xl')}
              style={{
                color: subThemeToColorMap[colorTheme],
              }}
            >
              {title}
            </div>
            {description ? (
              <div className="text-white w-full">{description}</div>
            ) : null}
            {completed === undefined || needed === undefined ? null : (
              <div className="flex items-center justify-end mt-4">
                <div className="flex items-center">
                  <RadialProgress
                    percentage={Math.round((completed / needed) * 100)}
                    color={mainThemeToColorMap[colorTheme]}
                    backgroundColor={thirdThemeToColorMap[colorTheme]}
                  />
                  <div className="ml-2">
                    <div
                      className={cx(
                        `text-${mainThemeToColorMap[colorTheme]}`,
                        'font-semibold text-xl',
                      )}
                    >
                      {`${completed}/${needed}`}
                    </div>
                    <div className="text-sm text-gray-500">Completed</div>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
      {onSuggestionClick ? (
        <div
          className={cx(
            `bg-${mainThemeToColorMap[colorTheme]}`,
            'rounded-b-md p-2 text-white flex items-center justify-between cursor-pointer',
          )}
          onClick={() => {
            onSuggestionClick();
          }}
        >
          <span>Show suggested classes</span>
          <ArrowRightIcon className="w-5 h-5" />
        </div>
      ) : null}
    </div>
  );
};
