import { useEffect, useState } from 'react';
import { LoadingOverlay } from '../../components/loading';
import { connect } from 'react-redux';
import { BookingEventObject } from '../../features/Booking/bookingEventContainer';
import VerticalMessageModal from '../../components/modals/verticalMessageModal/verticalMessageModal';
import { addAvailableClassesToUser } from '../../firebase/subscription/subscription';
import { trackBookingAnalytics } from '../../features/Booking/analytics';
import LoginRegisterBox from '../../components/forms/loginregister';
import { BookingPage } from './Booking/Booking';
import { ChoicePage } from './Choice/ChoicePage';
import { CheckOutPage } from './CheckOut/CheckOutPage';
import { getAllClasses } from '../../firebase/subscription/classes/classes';
import { createBookingDocument } from '../../firebase/subscription/bookings/bookings';
import { getFirstNameFromFullName } from '../../util/helper';
import { PrimaryButton } from '../../components/buttons/primary';
import { getUserWholeProfileByUID } from '../../firebase/configuration';
import { ProgressBarSteps } from '../../components/steps/progressBarSteps';
import PhoneInput from 'react-phone-number-input';
import RedAlert from '../../components/alerts/redAlert';
import { trackPricingPageEvents } from '../../features/PricingPage/analytics';
import { MotivationPage } from './MotivationPage/MotivationPage';
import { EnglishLevel } from './EnglishLevel/EnglishLevel';
import { Nationality } from './Nationality/Nationality';
import { BasedCountry } from './BasedCountry/BasedCountry';
import { Loading } from './Loading/Loading';
import { WhatsappIcon } from 'react-share';
import { analyticsIdentifyUser } from '../../analytics';
import { stringsPricingPage } from '../../util/localization';
import { DropDownFloat } from '../../components/forms/dropDownFloat/dropDownFloat';
import { PricingLanguageDropDown } from '../../features/PricingPage/localization/languageDropDown';
import { getGeoLocation } from '../../api/location';
import { currencySymbols, supportedCurrencies } from '../../util/currency';
import { referUser } from '../../api/social';
import { PersonalProgress } from './PersonalProgress/PersonalProgress';
import { generateMockClasses } from '../../util/mockGenerator/bookings';
import { EventBox } from '../../features/Booking/bookingEventContainerStacked/bookingEventContainer';
import { createWaitlist } from '../../api/waitlist';
import { ClassType } from '../../types/class';
import { CEFRLevel } from '../../types/level';
import { generateGuestPasses } from '../../api/guestPass';
import { JourneyPage } from './Journey/JourneyPage';
import { LevelProgram } from './LevelProgram/LevelProgram';

export interface FrequencyPricng {
  value: string;
  label: string;
  priceSuffix: string;
}

export const frequenciesPricing: Array<FrequencyPricng> = [
  {
    value: 'monthly',
    label: 'monthly',
    priceSuffix: '/month',
  },
  {
    value: 'annually',
    label: 'annually',
    priceSuffix: '/year',
  },
];

export function PricingPageComponent(props: {
  userData: any;
  onSetUserData: Function;
}) {
  const search = new URLSearchParams(window.location.search);
  let via = search.get('via');
  const lang = search.get('lang');
  const refCode = search.get('ref');
  const currency_ = search.get('currency');
  const plan = search.get('plan');
  const testGroup =
    search.get('group') ?? window.sessionStorage.getItem('group');

  if (testGroup) window.sessionStorage.setItem('group', testGroup);

  if (refCode && !via) {
    search.set('via', 'immigo-star');
    window.location.search = search.toString();
  }

  const [page, setPage] = useState(via ? 0 : 0);
  const [subOption, setSubOption] = useState<undefined | number>(undefined);
  const [frequency, setFrequency] = useState(frequenciesPricing[1]);
  const [loading, setLoading] = useState(true);
  const [wantRecur, setWantRecur] = useState(false);
  const [messageModalOpen, setMessageModalOpen] = useState(false);
  const [classList, setClassList] = useState<
    Array<BookingEventObject> | undefined
  >();
  const [chosenSchedule, setChosenSchedule] = useState<
    Array<BookingEventObject>
  >([]);
  const [phoneNumberModalOpen, setPhoneNumberModalOpen] = useState(false);
  const [phoneNumber, setPhoneNumber] = useState('');
  const [phoneNumberError, setPhoneNumberError] = useState(false);

  const [openClassWaitlistModal, setOpenClassWaitlistModal] = useState(false);
  const [chosenClassWaitlistClass, setChosenClassWaitlistClass] =
    useState<BookingEventObject>();

  // Profile customization questions
  const [motivation, setMotivation] = useState<string | undefined>(undefined);
  const [cefrLevel, setCefrLevel] = useState<string | undefined>(undefined);
  const [nationality, setNationality] = useState<string | undefined>(undefined);
  const [basedCountry, setBasedCountry] = useState<string | undefined>(
    undefined,
  );
  const [journey, setJourney] = useState<string | undefined>(undefined);
  const [language, setLanguage] = useState(lang ?? 'en');
  const [currency, setCurrency] = useState(
    currency_ && supportedCurrencies.includes(currency_) ? currency_ : 'usd',
  );

  const { userData } = props;

  const type = window.location.pathname.split('/')[2];

  const steps = via
    ? [
        { name: 'Motivation', page: 0 },
        { name: 'Nationality', page: 1 },
        { name: 'Based Country', page: 2 },
        { name: 'English Level', page: 4 },
        { name: 'Learning Plan', page: 5 },
        { name: 'Book Your Class', page: 6 },
        { name: 'Create Account', page: 7 },
        { name: 'Payment', page: 8 },
      ]
    : !userData
    ? [
        { name: 'Motivation', page: 0 },
        { name: 'Nationality', page: 1 },
        { name: 'Based Country', page: 2 },
        { name: 'English Level', page: 3 },
        { name: 'Loading', page: 4 },
        { name: 'Learning Plan', page: 5 },
        { name: 'Book Your Class', page: 6 },
        { name: 'Create Account', page: 7 },
        { name: 'Payment', page: 8 },
      ]
    : [
        { name: 'Motivation', page: 0 },
        { name: 'Nationality', page: 1 },
        { name: 'Based Country', page: 2 },
        { name: 'English Level', page: 3 },
        { name: 'Loading', page: 4 },
        { name: 'Learning Plan', page: 5 },
        { name: 'Book Your Class', page: 6 },
        { name: 'Payment', page: 8 },
      ];

  useEffect(() => {
    if (type === 'ready') setPage(6);
  }, [type]);
  useEffect(() => {
    stringsPricingPage.setLanguage(language);
  }, [language]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [page]);

  useEffect(() => {
    if (plan) {
      if (parseInt(plan) < 3) {
        setSubOption(parseInt(plan));
        setFrequency(frequenciesPricing[0]);
      } else {
        setSubOption(parseInt(plan));
        setFrequency(frequenciesPricing[0]);
      }
      if (!userData) setPage(8); // Goto login
    }
  });

  useEffect(() => {
    setLoading(false);
    if (
      userData?.cefrLevel &&
      userData?.nationality &&
      userData?.basedCountry &&
      userData?.livingAbroad &&
      subOption === undefined
    ) {
      setPage(4); // If the user already went through the on boarding then skip the questions
    }
  }, [userData]);

  useEffect(() => {
    updateGeo();
    updateClasses();
    analyticsIdentifyUser();
    updateInputs();
    trackBookingAnalytics('Visited pricing page', {
      userID: userData?.id,
      trial: type === 'paid',
      cefrLevel: cefrLevel ?? undefined,
      motivation: motivation ?? undefined,
      basedCountry: basedCountry ?? undefined,
      nationality: nationality ?? undefined,
    });
    document.body.classList.toggle('dark'); // make dark mode
  }, []);

  useEffect(() => {
    updateClasses();
  }, [cefrLevel]);

  useEffect(() => {
    if (phoneNumberError) setPhoneNumberError(false);
  }, [phoneNumber]);

  async function updateGeo() {
    const geo = await getGeoLocation();
    if (
      geo?.currency &&
      supportedCurrencies.includes(geo.currency?.toLocaleLowerCase())
    )
      setCurrency(geo.currency?.toLocaleLowerCase());
  }

  async function updateClasses() {
    const classes = await getAllClasses();
    const mockClasses = generateMockClasses(
      12,
      20,
      (cefrLevel ?? 'A2') as CEFRLevel,
      async (c: BookingEventObject) => {
        console.log(c);
        setChosenClassWaitlistClass(c);
        setOpenClassWaitlistModal(true);
        trackPricingPageEvents('Opened waitlist modal', {
          title: c.title,
          startMili: c.startMili,
          cefrLevel: c.recLevel,
        });
      },
    );
    console.log(cefrLevel);
    console.log(mockClasses);
    const combinedClasses = [...classes, ...mockClasses];
    setClassList(combinedClasses);
  }

  function updateInputs() {
    const m = window.sessionStorage.getItem('motivation');
    const n = window.sessionStorage.getItem('nationality');
    const b = window.sessionStorage.getItem('basedCountry');
    const c = window.sessionStorage.getItem('cefrLevel');

    if (m) {
      setMotivation(m);
      setPage(1);
    }
    if (n) {
      setNationality(n);
      setPage(2);
    }
    if (b) {
      setBasedCountry(b);
      setPage(3);
    }
    if (c) {
      setCefrLevel(c);
      setPage(4);
    }
  }

  console.log(chosenSchedule);

  async function onLogInSuccess(uid: string) {
    if (uid) {
      const user = await getUserWholeProfileByUID(uid);
      if (user) props.onSetUserData(user);
    }
    console.log(uid);
    setPage(10);
    setLoading(false);
  }

  function renderPage() {
    const pages = [
      <MotivationPage
        onNextClick={() => {
          setPage(1);
        }}
        setValue={(v: string) => {
          trackPricingPageEvents('Selected motivation on pricing page', {
            enteredValue: v,
          });
          window.sessionStorage.setItem('motivation', v);
          console.log(v);
          setMotivation(v);
        }}
      />,
      <Nationality
        onNextClick={() => {
          setPage(2);
        }}
        setValue={(v: string) => {
          trackPricingPageEvents('Selected nationality on pricing page', {
            enteredValue: v,
            motivation: motivation ?? undefined,
          });
          window.sessionStorage.setItem('nationality', v);
          setNationality(v);
        }}
      />,
      <BasedCountry
        onNextClick={() => {
          setPage(3);
          trackPricingPageEvents('Entered loading page on pricing page');
        }}
        setValue={(v: string) => {
          trackPricingPageEvents('Selected based country on pricing page', {
            enteredValue: v,
            motivation: motivation ?? undefined,
            nationality: nationality ?? undefined,
          });
          window.sessionStorage.setItem('basedCountry', v);
          setBasedCountry(v);
        }}
      />,
      <EnglishLevel
        onNextClick={() => {
          setPage(4);
        }}
        setValue={(v: string) => {
          trackPricingPageEvents('Selected CEFR Level on pricing page', {
            enteredValue: v,
            motivation: motivation ?? undefined,
            basedCountry: basedCountry ?? undefined,
            nationality: nationality ?? undefined,
          });
          window.sessionStorage.setItem('cefrLevel', v);
          setCefrLevel(v);
        }}
      />,
      <JourneyPage
        userData={userData}
        page={page}
        setPage={setPage}
        onJourneyChosen={(j: string) => {
          setJourney(j);
          setPage(5);
          console.log(j);
        }}
      />,
      <LevelProgram
        cefrLevel={cefrLevel ?? 'A2'}
        onNextClick={() => {
          setPage(6);
          trackPricingPageEvents('Pressed continue on level program page', {
            cefrLevel: cefrLevel ?? undefined,
            motivation: motivation ?? undefined,
            basedCountry: basedCountry ?? undefined,
            nationality: nationality ?? undefined,
          });
        }}
      />,
      // <BookingPage
      //   userData={userData}
      //   setPage={setPage}
      //   classes={classList}
      //   setChosenSchedule={setChosenSchedule}
      //   onBookingSubmit={() => {
      //     // if (!userData) {
      //     //   trackPricingPageEvents(
      //     //     'Phone number modal shown on the pricing page',
      //     //     {
      //     //       cefrLevel: cefrLevel ?? undefined,
      //     //       motivation: motivation ?? undefined,
      //     //       basedCountry: basedCountry ?? undefined,
      //     //       nationality: nationality ?? undefined,
      //     //     },
      //     //   );
      //     //   setPhoneNumberModalOpen(true);
      //     // }
      //   }}
      //   chosenSchedule={chosenSchedule}
      //   setWantRecur={setWantRecur}
      //   wantRecur={wantRecur}
      //   oneClassCap={type === 'trial'}
      //   setLoading={setLoading}
      //   cefr={cefrLevel}
      //   journey={journey}
      // />,
      <Loading
        onNextClick={() => {
          setPage(7);
        }}
      />,
      <PersonalProgress
        onNextClick={() => {
          setPage(8);
        }}
      />,
      // if (!userData) {
      //   trackPricingPageEvents(
      //     'Phone number modal shown on the pricing page',
      //     {
      //       cefrLevel: cefrLevel ?? undefined,
      //       motivation: motivation ?? undefined,
      //       basedCountry: basedCountry ?? undefined,
      //       nationality: nationality ?? undefined,
      //     },
      //   );
      //   setPhoneNumberModalOpen(true);
      // }
      <ChoicePage
        setFrequency={setFrequency}
        frequency={frequency}
        userData={userData}
        page={page}
        setPage={setPage}
        setSubOption={setSubOption}
        currency={currency}
        onCurrencyChange={(c: string) => {
          setCurrency(c);
        }}
        ready={type === 'ready'}
      />,
      <>
        <div className="mx-auto flex flex-col">
          <LoginRegisterBox
            defaultRegister
            onLoginSuccess={onLogInSuccess}
            userDataOverride={{
              whyLearnEnglish: motivation,
              phoneNumber: phoneNumber,
              cefrLevel: cefrLevel,
              nationality: nationality,
              basedCountry: basedCountry,
              livingAbroad: nationality !== basedCountry,
              testGroup: testGroup ?? 'NA',
            }}
          />
        </div>
      </>,
      <CheckOutPage
        trial={false}
        page={page}
        setPage={setPage}
        subOption={subOption}
        setLoading={setLoading}
        refCode={refCode ?? undefined}
        userData={userData}
        frequency={frequency}
        currency={currency}
        onSuccess={async (onComplete: Function) => {
          let allBookings: any[] = [];
          trackBookingAnalytics('Paid for the class subscription', {
            userID: userData?.id,
            subOption: subOption,
            trial: type === 'paid',
            cefrLevel: cefrLevel ?? undefined,
            motivation: motivation ?? undefined,
            basedCountry: basedCountry ?? undefined,
            nationality: nationality ?? undefined,
          });
          console.log(chosenSchedule);
          await Promise.all(
            chosenSchedule.map(async (booking) => {
              if (booking?.id && userData?.id) {
                const bookings = await createBookingDocument(
                  booking?.id,
                  booking?.startMili,
                  booking?.durationMili,
                  userData.id,
                  wantRecur ? 4 : 1,
                );
                allBookings = allBookings.concat(bookings);
                return bookings;
              }
            }),
          );
          if (refCode) await referUser(userData.id, refCode); // Refer the user if the referral code exists
          await addAvailableClassesToUser(userData.id, -allBookings.length); // Subtract the booked courses
          await generateGuestPasses([userData?.id], 3); // Give 3 guest passes by default
          onComplete();
          setMessageModalOpen(true);
        }}
      />,
    ];
    return pages[page];
  }

  return (
    <div
      className="w-full dark:bg-slate-950 md:px-72 px-8 overflow-y-scroll"
      translate="no"
    >
      <LoadingOverlay enabled={loading} />
      <VerticalMessageModal
        mainMessage={stringsPricingPage.thisClassComingSoon}
        subMessage={stringsPricingPage.leaveNumberBelow}
        theme="info"
        icon="exclamation"
        buttonText={stringsPricingPage.submit}
        isOpen={openClassWaitlistModal}
        onCloseModal={() => {
          setOpenClassWaitlistModal(false);
        }}
        onButtonClick={async () => {
          if (phoneNumber.length > 6 && chosenClassWaitlistClass) {
            if (
              chosenClassWaitlistClass.recLevel &&
              chosenClassWaitlistClass.tag
            )
              trackPricingPageEvents(
                'Submitted phone number in waitlist modal',
                {
                  title: chosenClassWaitlistClass?.title,
                  startMili: chosenClassWaitlistClass?.startMili,
                  cefrLevel: chosenClassWaitlistClass.recLevel,
                  motivation: motivation ?? undefined,
                  basedCountry: basedCountry ?? undefined,
                  nationality: nationality ?? undefined,
                },
              );
            await createWaitlist(
              chosenClassWaitlistClass.bookerID,
              chosenClassWaitlistClass.recLevel as CEFRLevel,
              chosenClassWaitlistClass.startMili,
              chosenClassWaitlistClass.title,
              chosenClassWaitlistClass.tag as ClassType,
              phoneNumber,
              chosenClassWaitlistClass.teacher?.id,
              chosenClassWaitlistClass.classID,
            );
            setOpenClassWaitlistModal(false); // Shortest # is 7 at the moment St Helena
          } else setPhoneNumberError(true);
        }}
      >
        <div className="my-10 max-w-[400px] mx-auto">
          {chosenClassWaitlistClass ? (
            <EventBox {...chosenClassWaitlistClass} />
          ) : null}
          <div className="block text-sm font-medium leading-6 text-gray-900 text-left mb-2 flex items-center">
            <WhatsappIcon size={20} round className="mr-2" />{' '}
            {stringsPricingPage.whatsAppNumber}
          </div>
          <PhoneInput
            value={phoneNumber}
            onChange={(n: string) => {
              setPhoneNumber(n);
            }}
          />
        </div>
        {phoneNumberError ? (
          <RedAlert text="Please enter a valid phone number with your country code!" />
        ) : null}
      </VerticalMessageModal>
      <VerticalMessageModal
        mainMessage={stringsPricingPage.stayOnTopOfYourClasses}
        subMessage={stringsPricingPage.weHelpStayOnTop}
        theme="success"
        icon="phone"
        buttonText={stringsPricingPage.submit}
        isOpen={phoneNumberModalOpen}
        onCloseModal={() => {
          trackPricingPageEvents(
            'Phone number modal closed on the pricing page',
            {
              cefrLevel: cefrLevel ?? undefined,
              motivation: motivation ?? undefined,
              basedCountry: basedCountry ?? undefined,
              nationality: nationality ?? undefined,
            },
          );
          setPhoneNumberModalOpen(false);
        }}
        onButtonClick={() => {
          if (phoneNumber.length > 6) {
            trackPricingPageEvents(
              'Submitted phone number on phone number modal in pricing page',
              {
                cefrLevel: cefrLevel ?? undefined,
                motivation: motivation ?? undefined,
                basedCountry: basedCountry ?? undefined,
                nationality: nationality ?? undefined,
              },
            );
            setPhoneNumberModalOpen(false); // Shortest # is 7 at the moment St Helena
          } else setPhoneNumberError(true);
        }}
        stayOpenOnPrimaryClick
      >
        <div className="my-10 max-w-[400px] mx-auto">
          <div className="block text-sm font-medium leading-6 text-gray-900 text-left mb-2 flex items-center">
            <WhatsappIcon size={20} round className="mr-2" />{' '}
            {stringsPricingPage.whatsAppNumber}
          </div>
          <PhoneInput
            value={phoneNumber}
            onChange={(n: string) => {
              setPhoneNumber(n);
            }}
          />
        </div>
        {phoneNumberError ? (
          <RedAlert text="Please enter a valid phone number with your country code!" />
        ) : null}
      </VerticalMessageModal>
      <VerticalMessageModal
        mainMessage="You are in!"
        subMessage="We officially have you in our exclusive Immigo subscription program. Now let's complete your profile for your customized experience."
        theme={'success'}
        buttonText={'Continue'}
        onButtonClick={() => {
          trackBookingAnalytics(
            'Clicked continue on pricing page paid confirmation',
            {
              userID: userData?.id,
              subOption: subOption,
              cefrLevel: cefrLevel ?? undefined,
              motivation: motivation ?? undefined,
              basedCountry: basedCountry ?? undefined,
              nationality: nationality ?? undefined,
            },
          );
          setMessageModalOpen(false);
          window.location.href = '/get-started';
        }}
        onCloseModal={() => {
          trackBookingAnalytics('Closed pricing page paid confirmation modal', {
            userID: userData?.id,
            subOption: subOption,
            cefrLevel: cefrLevel ?? undefined,
            motivation: motivation ?? undefined,
            basedCountry: basedCountry ?? undefined,
            nationality: nationality ?? undefined,
          });
          setMessageModalOpen(false);
          window.location.href = '/get-started';
        }}
        isOpen={messageModalOpen}
      />

      {!userData?.subProductID ? (
        <>
          <div className="flex flex-shrink-0 items-center md:px-4">
            <img
              className="mt-4 md:h-6 h-10 w-auto"
              src="https://cdn.prod.website-files.com/644c0ecc4a055c564b922d0b/645529068ac0540fd0b83c49_Immigo%20White%20Logo.svg"
              alt="Immigo logo"
            />
          </div>
          <div className="mb-8 md:mt-8 mt-0 max-w-[500px] mx-auto">
            <ProgressBarSteps steps={steps} currentStep={page} hideLabels />
            {/* <HorizontalArrowSteps steps={steps} chosenPage={page} /> */}
          </div>
          <div className="items-center justify-center md:mt-16 mt-2 pb-10">
            {renderPage()}
          </div>
          <PricingLanguageDropDown
            language={language}
            onLanguageChosen={(l: string) => {
              stringsPricingPage.setLanguage(l);
              setLanguage(l);
            }}
          />
        </>
      ) : (
        <div
          className="shadow-lg w-full border-2 p-3
          font-medium bg-white
          rounded-lg
          mb-10 max-w-[500px]"
        >
          <img
            className="md:h-12 h-12 w-auto mb-5"
            src="https://immigoimages.s3.amazonaws.com/Immigo_new_logo.png"
            alt="Immigo logo"
          />
          <span className="block mb-4">
            Hi {getFirstNameFromFullName(userData.name)}!
          </span>
          Seems like you are already enrolled in our subscription plan. If you
          wish to change your learning plan, please contact our team at
          <a href="mailto: ricardo@immigo.io" className="text-blue-immigo">
            {' '}
            ricardo@immigo.io
          </a>
          .
          <div className="my-5">
            <div>Learning plan options:</div>
            <div>
              <span className="text-blue-immigo">4 classes /4 weeks: </span>
              $14.8 per class ($59
              <span className="line-through text-gray-400">$79</span> total)
            </div>
            <div>
              <span className="text-blue-immigo">8 classes /4 weeks: </span>
              $11.1 per class ($79
              <span className="line-through text-gray-400">$109</span> total)
            </div>
            <div>
              <span className="text-blue-immigo">12 classes /4 weeks </span>$9.9
              per class ($119
              <span className="line-through text-gray-400">$139</span> total)
            </div>
          </div>
          <PrimaryButton
            className="mt-10"
            text="View my learning plan"
            onClick={() => {
              window.location.href = '/subscription';
            }}
          />
        </div>
      )}
    </div>
  );
}

const mapStateToProps = (state: any) => ({
  userData: state.sessionState.userData,
});

const mapDispatchToProps = (dispatch: any) => ({
  onSetUserData: (userData: any) =>
    dispatch({ type: 'USER_DATA_SET', userData }),
});

export const PricingPage = connect(
  mapStateToProps,
  mapDispatchToProps,
)(PricingPageComponent);
