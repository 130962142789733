import { getCorrectionAI, getCorrectionAIContext } from '../api';
import { Correction } from '../types/feedback';
import { patienceDiffPlus } from './diff';

export function generateCorrectionPairWithStyle(
  toBeCorrected: string,
  correction: string,
): Correction {
  const diff = patienceDiffPlus(toBeCorrected, correction);
  let diffIndexa = -1;
  let diffIndexb = -1;
  let aoffset = 0;
  let boffset = 0;
  diff.lines.map((line, index) => {
    if (line.bIndex === -1 && diffIndexa === -1) {
      toBeCorrected =
        toBeCorrected.slice(0, line.aIndex + aoffset) +
        '<span style="color:red;text-decoration:line-through">' +
        toBeCorrected.slice(line.aIndex + aoffset);
      diffIndexa = line.aIndex;
      aoffset += 53; // Number of letters in the HTML code to be inserted (This is dangerous probably)
    }
    if (diffIndexa !== -1 && diff.lines[index + 1]?.bIndex !== -1) {
      toBeCorrected =
        toBeCorrected.slice(0, line.aIndex + aoffset + 1) +
        '</span>' +
        toBeCorrected.slice(line.aIndex + aoffset + 1);
      diffIndexa = -1;
      aoffset += 7;
    }
    if (line.aIndex === -1 && diffIndexb === -1) {
      correction =
        correction.slice(0, boffset + line.bIndex) +
        '<span style="color:green">' +
        correction.slice(boffset + line.bIndex);
      diffIndexb = line.bIndex;
      boffset += 26;
    }
    if (diffIndexb !== -1 && diff.lines[index + 1]?.aIndex !== -1) {
      correction =
        correction.slice(0, line.bIndex + boffset + 1) +
        '</span>' +
        correction.slice(line.bIndex + boffset + 1);
      diffIndexb = -1;
      boffset += 7;
    }
  });

  return {
    original: toBeCorrected,
    fixed: correction,
  };
}

export async function getCorrectionPair(
  toBeCorrected: string,
  useContext?: boolean,
): Promise<Correction | undefined> {
  let tobeCorrectedArray = toBeCorrected.split(' ');
  if (tobeCorrectedArray.length < 2) return; // Things like "Yeah" , "Okay" should not be corrected
  const response = useContext
    ? await getCorrectionAIContext(toBeCorrected)
    : await getCorrectionAI(toBeCorrected);
  let correctionPair: Correction;

  let correction = response?.data?.correction;
  let explanation = response?.data?.explanation;
  let mistake = response?.data?.mistake;
  if (correction?.[correction?.length - 1]?.match(/[?.!,]/))
    correction = correction.slice(0, correction.length - 1);

  if (
    !correction ||
    !toBeCorrected ||
    correction?.toLowerCase().trim() === toBeCorrected?.toLowerCase().trim() ||
    toBeCorrected?.includes(correction) ||
    correction?.includes(toBeCorrected) || // If this is an extension, it's not a correction
    explanation?.includes('repeat') || // If the advice is just repetition, it's not important
    explanation?.includes('duplicate') ||
    !explanation // No explanation -> already correct
  )
    return;
  else {
    correctionPair = {
      original: toBeCorrected,
      fixed: correction,
    };
    if (explanation) correctionPair.explanation = explanation;
    if (mistake) correctionPair.mistake = mistake;
  }

  // Need to save correction
  return correctionPair;
}
