import axios from 'axios';
import { UserPreviewActivityType } from '../firebase/subscription/subscription';

const endPoint =
  process.env.REACT_APP_ENV === 'PROD'
    ? 'https://immigo-api.herokuapp.com/bookings'
    : 'http://localhost:8080/bookings'; // Dev env

export async function getMostRecentAttendedPreviousBooking(bookingID: string) {
  const data = await axios.get(
    `${endPoint}/getMostRecentAttendedPreviousBooking/${bookingID}`,
  );
  return data?.data;
}

export async function getIsClassRecommended(userID: string, classID: string) {
  const data = await axios.get(
    `${endPoint}/isClassRecommended/${userID}/${classID}`,
  );
  return data?.data;
}

export async function getOrGeneratePreviewActivityForBooking(
  bookingID: string,
) {
  const data = await axios.post(`${endPoint}/getPreviewActivityForBooking`, {
    bookingID,
  });
  return data?.data;
}

export async function updateUserPreviewActivityProgress(
  bookingID: string,
  activityType: UserPreviewActivityType,
) {
  const data = await axios.post(
    `${endPoint}/updateUserPreviewActivityProgress`,
    {
      bookingID,
      activityType,
    },
  );
  return data?.data;
}

export async function getUserDailyAttendanceHistory(uid: string) {
  const data = await axios.get(
    `${endPoint}/getUserDailyAttendanceHistory/${uid}`,
    {
      headers: {
        'X-API-KEY': process.env.REACT_APP_IMMIGO_API_KEY ?? '',
      },
    },
  );
  return data?.data;
}

export async function getRecClasses(cefrLevel: string, motive?: string) {
  const data = await axios.get(
    `${endPoint}/getRecClasses/${cefrLevel}/${motive}`,
  );
  return data?.data?.recClasses;
}

export async function getCityThemedClassesThisWeek(cityTag?: string) {
  const data = await axios.get(
    `${endPoint}/getCityThemedClassesThisWeek/${cityTag ?? ''}`,
  );
  return data?.data?.recClasses;
}
