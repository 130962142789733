import { DateTime } from 'luxon';
import moment from 'moment';
import { BookingData } from '../firebase/subscription/subscription';
import momentTimezone from 'moment-timezone';
import { dayInMili } from '../usefuldata/mili';

export const formatUTCDateToUserTimezone = (date: string | number) => {
  return DateTime.fromMillis(+date)
    .toLocal()
    .toFormat('DD HH:mm');
};

export const returnLatestDateGivenArrayOfDateStrings = (
  dates: Array<string>,
) => {
  dates.sort((d1, d2) => {
    return new Date(d2).valueOf() - new Date(d1).valueOf();
  });
  return dates[0];
};

export const areEventsTheSameDay = (dateOne: number, dateTwo: number) =>
  DateTime.fromMillis(dateOne).startOf('day').toMillis() ===
  DateTime.fromMillis(dateTwo).startOf('day').toMillis();

export const isMeetingStillActive = (dateToCompare: string | number) => {
  const HOURS_TO_LET_THE_MEETING_ACTIVE_AFTER_START = 1;

  const calculatedDate = DateTime.fromMillis(+dateToCompare)
    .toLocal()
    .plus({ hour: HOURS_TO_LET_THE_MEETING_ACTIVE_AFTER_START });
  const today = DateTime.now();

  return calculatedDate.toMillis() > today.toMillis();
};
export const enumerateDaysBetweenDates = function (
  startDate: moment.Moment,
  endDate: moment.Moment,
): Array<moment.Moment> {
  var dates = [startDate];

  var currDate = moment(startDate).startOf('day');
  var lastDate = moment(endDate).startOf('day');

  while (currDate.add(1, 'days').diff(lastDate) < 0) {
    dates.push(currDate.clone());
  }

  dates.push(endDate);

  return dates;
};

export const getFirstDayOfTheMonth = (date: moment.Moment): moment.Moment => {
  return moment(date).startOf('month');
};

export const getLastDayOfTheMonth = (date: moment.Moment): moment.Moment => {
  return moment(date).endOf('month');
};

// Gets a comprehensive list of all the dates based on Monday - Sunday calendar representation
export const getCalendarDatesGivenCurrentDate = (
  date: moment.Moment,
): Array<moment.Moment> => {
  const firstDay = getFirstDayOfTheMonth(date);
  const lastDay = getLastDayOfTheMonth(date);

  console.log(firstDay.format());

  let firstMonday = firstDay.clone().subtract(firstDay.day() - 1, 'days');
  let lastSunday = lastDay.clone().add(7 - lastDay.day(), 'days');

  // edge case when Sunday is first of the month
  if (firstDay.day() === 0 && firstDay.date() === 1) {
    firstMonday = firstDay.clone().subtract(firstDay.day() + 6, 'days');
  }

  return enumerateDaysBetweenDates(firstMonday, lastSunday); // Get list of days from firstMonday to lastSunday
};

export const convertMiliTimeToImmediateWeekDate = (date: number): number => {
  const convertedMili = convertMiliTimeToChosenWeekTime(
    date,
    new Date().valueOf(),
  );
  const convertedMoment = moment(convertedMili);
  if (convertedMoment.valueOf() - dayInMili <= new Date().valueOf()) {
    convertedMoment.add(1, 'week'); // Add a week if the convertee day is before current day (ie, Monday vs Thursday)
  }

  return convertedMoment.valueOf();
};

// EX) If toConvertMili date is 4/21/2023 (Friday) and chosenWeekMili is 4/28/2023 (Thursday) it will produce 4/29/2023 (Friday)
export const convertMiliTimeToChosenWeekTime = (
  toConvertMili: number,
  chosendayMili: number,
): number => {
  const resultMoment = moment(toConvertMili); // Limit to 24 hours
  const chosenDayMoment = moment(chosendayMili);

  // console.log(resultMoment.format('dddd MMMM Do, YYYY [at] hh:mm A'));
  // console.log(moment(toConvertMili).day());
  console.log(resultMoment.format());
  console.log(chosenDayMoment.format());
  // Set the year and week first to chosenWeek's year and week
  resultMoment
    .set('year', chosenDayMoment.year())
    .set('month', chosenDayMoment.month())
    .set('week', chosenDayMoment.week())
    .set('day', moment(toConvertMili).day())
    .valueOf();
  // console.log(resultMoment.format('dddd MMMM Do, YYYY [at] hh:mm A'));
  // console.log(moment(toConvertMili).day());
  console.log(chosenDayMoment.format());
  console.log(resultMoment.format());
  console.log(chosenDayMoment.week());

  console.log('after');
  // If the result is still less than chosen day then add 1 more week
  if (resultMoment.day() < chosenDayMoment.day()) {
    resultMoment.add(1, 'week');
  }

  //Skipping a year
  if (resultMoment.month() - chosenDayMoment.month() === -11) {
    resultMoment
      .set('year', chosenDayMoment.year() + 1)
      .set('month', chosenDayMoment.month())
      .set('week', chosenDayMoment.week())
      .set('day', moment(toConvertMili).day())
      .valueOf();
  }

  console.log(resultMoment.month() - chosenDayMoment.month());
  console.log(resultMoment.format());

  const timeZone = momentTimezone.tz.guess();
  const timeChange =
    moment(resultMoment.valueOf()).utcOffset() -
    moment(toConvertMili).utcOffset();
  const timeChangeUS =
    momentTimezone(resultMoment.valueOf()).tz('America/New_York').utcOffset() -
    momentTimezone(toConvertMili).tz('America/New_York').utcOffset();

  if (
    timeZone !== 'America/Los_Angeles' &&
    timeZone !== 'America/New_York' &&
    timeZone !== 'America/Chicago' &&
    timeZone !== 'America/Vancouver' &&
    timeZone !== 'America/Denver' &&
    timeZone !== 'America/Edmonton' &&
    timeZone !== 'America/Winnipeg' &&
    timeZone !== 'America/Indianapolis' &&
    timeZone !== 'America/Montreal' &&
    timeZone !== 'America/Boise' &&
    timeZone !== 'America/Detroit' &&
    timeZone !== 'America/Fort_Wayne' &&
    timeZone !== 'America/Indiana/Indianapolis' &&
    timeZone !== 'America/Indiana/Knox' &&
    timeZone !== 'America//Indiana/Marengo' &&
    timeZone !== 'America/Indiana/Petersburg' &&
    timeZone !== 'America/Indiana/Tell_City' &&
    timeZone !== 'America/Indiana/Vevay' &&
    timeZone !== 'America/Indiana/Vincennes' &&
    timeZone !== 'America/Indianapolis' &&
    timeZone !== 'America/Juneau' &&
    timeZone !== 'America/Kentucky/Louisville' &&
    timeZone !== 'America/Kentucky/Monticello' &&
    timeZone !== 'America/Louisville' &&
    timeZone !== 'America/Menominee' &&
    timeZone !== 'America/Metlakatla' &&
    timeZone !== 'America/North_Dakota/Beulah' &&
    timeZone !== 'America/North_Dakota/Center' &&
    timeZone !== 'America/North_Dakota/New_Salem'
  ) {
    resultMoment.add(timeChange / 60, 'h');
  } else {
    resultMoment.add(timeChangeUS / 60, 'h');
  }

  return resultMoment.valueOf();
};

export const returnMiliAfterCheckingIfClassHasPassed = (
  startMili: number,
  durationMili: number,
): number => {
  const resultMoment = moment(
    convertMiliTimeToChosenWeekTime(startMili, moment().valueOf()),
  ); // Limit to 24 hours
  console.log(
    `${moment(startMili).format('dddd')} ${resultMoment.format(
      'dddd',
    )} ${resultMoment.format('YYYY/MM/DD')}`,
  );
  const nowMoment = moment();

  // // Set the year and week first to chosenWeek's year and week
  // resultMoment
  //   .set('year', nowMoment.year())
  //   .set('week', nowMoment.week())
  //   .valueOf();

  // If the result is still less than chosen day then add 1 more week
  if (
    resultMoment.month() - nowMoment.month() > -2 &&
    resultMoment.valueOf() + durationMili < nowMoment.valueOf()
  ) {
    resultMoment.add(1, 'week');
  }

  return resultMoment.valueOf();
};

export const getUpcomingBookings = (bookings: Array<BookingData>) => {
  return bookings.filter(
    (b) => !b.canceled && b.startMili + b.durationMili >= new Date().valueOf(),
  );
};
