import axios from 'axios';
import { useEffect, useState } from 'react';
import { SpeechMaticTranscriptResult } from '../../types/speechmatic';
import { BackupBrowserTranscriber } from './backupTranscriber';

const endPoint =
  process.env.REACT_APP_ENV === 'PROD'
    ? 'https://immigo-api.herokuapp.com'
    : 'http://localhost:8080'; // Dev env

export const BackgroundTranscriber = (props: {
  isTranscribing: boolean;
  onConnectionCreated: Function;
  onIncomingText: Function;
  onIncomingResults: Function;
  onPartialTranscript?: Function;
  onError: Function;
  isRecording: boolean;
}) => {
  const [ready, setReady] = useState(false);
  const [seqNo, setSeqNo] = useState(0);
  const [incomingText, setIncomingText] = useState('');
  const [incomingResults, setIncomingResults] = useState<
    Array<SpeechMaticTranscriptResult>
  >([]);
  const [resultsArray, setResultsArray] = useState<
    Array<SpeechMaticTranscriptResult>
  >([]);
  const [text, setText] = useState('');
  const [partialTranscript, setPartialTranscript] = useState('');
  const [activateBackupStandby, setActivateBackupStandby] = useState(false);
  const [socket, setSocket] = useState<undefined | WebSocket>(undefined);

  const {
    isTranscribing,
    onConnectionCreated,
    onIncomingText,
    onIncomingResults,
    onError,
    isRecording,
    onPartialTranscript,
  } = props;

  async function establistConnection() {
    try {
      const jwtData = await axios.post(
        `${endPoint}/database/auth_speechmatics`,
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${'aYHv1cxVszvnCv7CoVWeqOh7O8ccP5Aq'}`,
          },
        },
      );
      // await updateCorrectionDocument(); // Update the correction data
      const jwt = jwtData?.data?.data?.key_value;

      console.log(jwt);
      const socketClient = new WebSocket(
        `wss://eu.rt.speechmatics.com/v2/en?jwt=${jwt}`,
      );

      socketClient.onopen = function (e) {
        console.log('[open] Connection established');
        console.log('Sending to server');
        console.log(e);
        socketClient.send(
          `{"message": "StartRecognition", 
                "audio_format": {"type": "raw", "encoding": "pcm_s16le", "sample_rate": 16000},
                "transcription_config": {
                  "language": "en", 
                  "operating_point": "enhanced", 
                  "enable_entities": true, 
                  "diarization": "speaker", 
                  "speaker_diarization_config": { "max_speakers": 10 }, 
                  "enable_partials": true, 
                  "max_delay": 5,
                  "max_delay_mode": "flexible",
                  "punctuation_overrides": {
                    "permitted_marks": [
                      ".",
                      ",",
                      "!",
                      "?"
                    ],
                    "sensitivity": 0.5
                  },
                  "additional_vocab": [
                    {
                      "content": "Immigo",
                      "sounds_like": [
                        "Mingo",
                        "amigo",
                        "in amigo"
                      ]
                    },
                    {
                      "content": "firmly",
                      "sounds_like": [
                        "family"
                      ]
                    },
                    {
                      "content": "hi",
                      "sounds_like": [
                        "high"
                      ]
                    },
                    {
                      "content": "morning",
                      "sounds_like": [
                        "mourning"
                      ]
                    }
                  ]
                }
              }`,
        );
      };
      socketClient.onmessage = function (event) {
        const data = JSON.parse(event.data);

        const { message } = data;

        // console.log(event);

        switch (message) {
          case 'AddTranscript':
            setIncomingText(data?.metadata?.transcript);
            console.log(data?.metadata?.transcript);
            if (data?.results) {
              setIncomingResults(data.results);
            }
            break;
          case 'RecognitionStarted':
            setReady(true);
            onConnectionCreated(socketClient);
            break;
          case 'AddPartialTranscript':
            console.log(data?.metadata?.transcript);
            onPartialTranscript?.(data?.metadata?.transcript);
            setPartialTranscript(data?.metadata?.transcript);
            break;
          case 'AudioAdded':
            setSeqNo(data?.seq_no);
            break;
          case 'EndOfTranscript':
            console.log('message');
            break;
          default:
        }
      };

      socketClient.onerror = function (err) {
        onError(JSON.stringify(err));
        console.log(`error ${JSON.stringify(err)}`);
        socketClient.close(); // Close connection if there's an error
        setActivateBackupStandby(true);
        onConnectionCreated(undefined);
      };
      setSocket(socketClient);
    } catch (err) {
      onError(JSON.stringify(err));
    }
  }

  useEffect(() => {
    if (isTranscribing) {
      establistConnection();
    } else if (socket && !isTranscribing) {
      console.log('Ending Stream');
      socket.send(`{"message":"EndOfStream","last_seq_no":${seqNo}}`); // End the stream
    }
  }, [isTranscribing]);

  useEffect(() => {
    if (incomingText) onIncomingText(text, incomingText, setText);
  }, [incomingText]);

  useEffect(() => {
    setResultsArray([...resultsArray, ...incomingResults]);
    if (incomingResults)
      onIncomingResults([...resultsArray, ...incomingResults]);
  }, [incomingResults]);

  return (
    <>
      <BackupBrowserTranscriber
        activate={activateBackupStandby && isRecording}
        onFinalTranscript={(x: SpeechMaticTranscriptResult) => {
          onIncomingResults([], [x]);
          console.log(x);
        }}
      />
    </>
  );
};
