import { Cog6ToothIcon } from '@heroicons/react/24/outline';
import { useEffect, useRef, useState } from 'react';
import { ProgressBarStepsDynamic } from '../../../components/steps/progressBarDynamic';
import { ProgressBarSteps } from '../../../components/steps/progressBarSteps';
import Block from './lf30_scsjieeg.gif';
import { stringsPricingPage } from '../../../util/localization';

function useInterval(callback: Function, delay: number, stop: number) {
  const savedCallback: any = useRef();

  // Remember the latest callback.
  useEffect(() => {
    savedCallback.current = callback;
  }, [callback]);

  // Set up the interval.
  useEffect(() => {
    let id = setInterval(() => {
      savedCallback.current();
    }, delay);
    setTimeout(() => {
      clearInterval(id);
    }, stop);
    return () => clearInterval(id);
  }, [delay]);
}

export const Loading = (props: { onNextClick: Function }) => {
  const { onNextClick } = props;

  const [progress, setProgress] = useState(0);

  useInterval(
    () => {
      setProgress(progress + 20);
      if (progress > 110) onNextClick?.();
    },
    800,
    11000,
  );

  return (
    <div className="min-w-[320px] max-w-[600px] items-center h-full mx-auto">
      <Cog6ToothIcon className="w-48 h-48 delay-200 motion-safe:animate-spin text-blue-immigo mx-auto" />
      <ProgressBarStepsDynamic
        steps={[
          { name: stringsPricingPage.findingRightPlanForYou, page: 0 },
          { name: stringsPricingPage.loadingCustomizedClasses, page: 1 },
        ]}
        currentProgress={progress}
      />
    </div>
  );
};
