import { Menu } from '@headlessui/react';
import {
  BookOpenIcon,
  CalendarIcon,
  ChartBarIcon,
  ClockIcon,
  DocumentTextIcon,
  LinkIcon,
  TrashIcon,
  UserGroupIcon,
  VideoCameraIcon,
} from '@heroicons/react/24/outline';
import { cx } from 'classix';
import moment from 'moment';
import { useEffect, useState } from 'react';
import AvatarGroupStacked from '../../../components/avatar/avatarGroupStacked/avatarGroupStacked';
import UserTable, {
  UserTableObject,
} from '../../../components/userTable/userTable';
import {
  BookingData,
  getSessionBookers,
} from '../../../firebase/subscription/subscription';
import { BookerData } from '../../../types/bookings';
import { capitalizeEveryFirstLetter } from '../../../util/standardization';
import { atcb_action } from 'add-to-calendar-button';
import { trackMyClassesAnalytics } from '../analytics';

interface UpcomingClassContainerProps extends BookingData {
  onTrashClick: Function;
  onClassLinkClick: Function;
}

export const LargerClassContainer = ({
  id,
  startMili,
  title,
  slide,
  cleanSlide,
  recording,
  recurring,
  classSessionID,
  durationMili,
  onTrashClick,
  onClassLinkClick,
}: UpcomingClassContainerProps) => {
  const [timeLeftToStartMoment, setTimeLeftToStart] = useState<
    moment.Duration | undefined
  >();
  const [bookersList, setBookersList] = useState<Array<BookerData>>([]);

  console.log(cleanSlide);

  const startingSoon =
    startMili + durationMili >= new Date().valueOf() &&
    startMili - new Date().valueOf() < 1000 * 60 * 60 * 24;
  const inProgress =
    startMili <= new Date().valueOf() &&
    startMili + durationMili >= new Date().valueOf();

  const classInPast = startMili + durationMili < new Date().valueOf();

  useEffect(() => {
    updateBookers();
    if (startingSoon) {
      setTimeLeftToStart(moment.duration(startMili - new Date().valueOf()));
      setInterval(() => {
        setTimeLeftToStart(moment.duration(startMili - new Date().valueOf()));
      }, 1000);
    }
  }, []);

  async function updateBookers() {
    if (!classSessionID) return;
    const bookers: Array<BookerData> = (await getSessionBookers(
      classSessionID,
    )) as Array<BookerData>;
    const attended = !classInPast ? bookers : bookers.filter((b) => b.attended);
    setBookersList(attended);
  }

  return (
    <li
      key={id + '-' + startMili}
      className={'relative flex py-6 xl:static w-full'}
    >
      <div className="flex-auto w-full">
        <h3
          className={cx(
            startingSoon ? 'text-blue-immigo' : 'text-gray-900',
            ' pr-10 xl:pr-0',
          )}
        >
          {capitalizeEveryFirstLetter(title)}
        </h3>
        <dl className="mt-2 flex text-sm flex-col text-gray-500 xl:flex-row">
          <div className="flex items-center space-x-3">
            <dt className="mt-0.5">
              <span className="sr-only">Date</span>
              <CalendarIcon
                className="h-5 w-5 text-gray-400"
                aria-hidden="true"
              />
            </dt>
            <dd>
              <time dateTime={moment(startMili).format()}>
                {`${moment(startMili)
                  .local()
                  .format('MMMM Do, YYYY [at] hh:mm A')} - ${moment(startMili)
                  .add(durationMili)
                  .local()
                  .format('hh:mm A')}`}
              </time>
            </dd>
          </div>
          {recurring ? (
            <div className="mt-2 flex items-center space-x-3 xl:ml-3.5 xl:mt-0 xl:border-l xl:border-gray-400 xl:border-opacity-50 xl:pl-3.5">
              <dt className="mt-0.5">
                <span className="sr-only">Schedule</span>
                <ClockIcon
                  className="h-5 w-5 text-gray-400"
                  aria-hidden="true"
                />
              </dt>
              <dd>Booked every {moment(startMili).format('dddd')}</dd>
            </div>
          ) : null}
        </dl>
        <dl className="mt-2 text-sm">
          <div className="flex items-center space-x-3 text-gray-500">
            <dt className="mt-0.5">
              <span className="sr-only">Link</span>
              <DocumentTextIcon
                className="h-5 w-5 text-gray-400"
                aria-hidden="true"
              />
            </dt>
            <dd className="max-w-md">
              <a
                target="_blank"
                href={slide ?? cleanSlide}
                className="cursor-pointer hover:text-blue-immigo"
                rel="noreferrer"
              >
                {slide ?? cleanSlide}
              </a>
            </dd>
          </div>
        </dl>
        <dl className="mt-2 text-sm">
          <div className="flex items-center space-x-3 text-gray-500">
            <dt className="mt-0.5">
              <span className="sr-only">Link</span>
              <BookOpenIcon
                className="h-5 w-5 text-gray-400"
                aria-hidden="true"
              />
            </dt>
            <dd className="max-w-md truncate">
              {slide ? (
                <div
                  onClick={(e) => {
                    e.preventDefault();
                    trackMyClassesAnalytics(
                      'Clicked slide link on My Classes page class container',
                      {
                        bookingTitle: title,
                        bookingStartMili: startMili,
                      },
                    );
                    window.open(slide, '_blank', 'noopener,noreferrer');
                  }}
                  className="cursor-pointer hover:text-blue-immigo"
                  rel="noreferrer"
                >
                  {slide}
                </div>
              ) : (
                <span>
                  {!classInPast ? (
                    <a
                      className="hover:text-blue-immigo"
                      target="_blank"
                      href={`${window.location.origin}/preview?bid=${id}`}
                      rel="noreferrer"
                    >{`${window.location.origin}/preview?bid=${id}`}</a>
                  ) : (
                    <a
                      className="hover:text-blue-immigo"
                      target="_blank"
                      href={`${window.location.origin}/insight?bid=${id}`}
                      rel="noreferrer"
                    >{`${window.location.origin}/insight?bid=${id}`}</a>
                  )}
                </span>
              )}
            </dd>
          </div>
        </dl>
        <dl className="mt-2 text-sm">
          <div className="flex items-center space-x-3 text-gray-500">
            <dt className="mt-0.5">
              <span className="sr-only">Link</span>
              <VideoCameraIcon
                className="h-5 w-5 text-gray-400"
                aria-hidden="true"
              />
            </dt>
            <dd className="truncate">
              {recording ? (
                <a
                  onClick={(e) => {
                    e.preventDefault();
                    trackMyClassesAnalytics(
                      'Clicked recording link on My Classes page class container',
                      {
                        bookingTitle: title,
                        bookingStartMili: startMili,
                      },
                    );
                    window.open(recording, '_blank', 'noopener,noreferrer');
                  }}
                  className="cursor-pointer hover:text-blue-immigo flex"
                  target={'_blank'}
                  href={recording}
                  rel="noreferrer"
                >
                  {recording}
                </a>
              ) : (
                <span>
                  {!classInPast
                    ? 'Recording link will be available after the class!'
                    : 'Recording link will be here soon!'}
                </span>
              )}
            </dd>
          </div>
        </dl>
        {classInPast ? (
          <dl className="mt-2 text-sm">
            <div className="flex items-center space-x-3 text-gray-500 hover:text-blue-immigo">
              <dt className="mt-0.5">
                <span className="sr-only">Insight</span>
                <ChartBarIcon
                  className="h-5 w-5 text-gray-400"
                  aria-hidden="true"
                />
              </dt>
              <dd className="max-w-md truncate">
                <a
                  target="_blank"
                  href={`${window.location.origin}/insight?bid=${id}`}
                  rel="noreferrer"
                >{`${window.location.origin}/insight?bid=${id}`}</a>
              </dd>
            </div>
          </dl>
        ) : null}
        {!classInPast ? (
          <dl className="text-sm mt-2">
            <div className="flex items-center space-x-3 text-gray-500">
              <dt className="mt-0.5">
                <span className="sr-only">Date</span>
                <LinkIcon
                  className="h-5 w-5 text-gray-400"
                  aria-hidden="true"
                />
              </dt>
              <dd
                className="truncate"
                onClick={() => {
                  onClassLinkClick();
                }}
              >
                {classSessionID ? (
                  <a
                    onClick={(e) => {
                      e.preventDefault();
                      trackMyClassesAnalytics(
                        'Clicked class link on My Classes page class container',
                        {
                          bookingTitle: title,
                          bookingStartMili: startMili,
                        },
                      );
                    }}
                    className="cursor-pointer hover:text-blue-immigo"
                    target={'_blank'}
                    href={`${window.location.origin}/class/${classSessionID}`}
                    rel="noreferrer"
                  >{`${window.location.origin}/class/${classSessionID}`}</a>
                ) : (
                  `Link coming soon! (Will be available before the class)`
                )}
              </dd>
            </div>
          </dl>
        ) : null}
        {startingSoon && timeLeftToStartMoment && !inProgress ? (
          <dl className="mt-2 text-sm">
            <div className="flex items-start space-x-3 text-gray-500">
              <dt className="mt-0.5">
                <span className="sr-only">Date</span>
                <ClockIcon
                  className="h-5 w-5 text-gray-400"
                  aria-hidden="true"
                />
              </dt>
              <dd>
                {`Starting in ${
                  timeLeftToStartMoment.days()
                    ? timeLeftToStartMoment.days() + ' days '
                    : ''
                }
            ${
              timeLeftToStartMoment.hours()
                ? timeLeftToStartMoment.hours() + ' hours'
                : ''
            }
            ${
              timeLeftToStartMoment.minutes()
                ? timeLeftToStartMoment.minutes() + ' minutes'
                : ''
            } 
            ${
              timeLeftToStartMoment.seconds()
                ? timeLeftToStartMoment.seconds() + ' seconds'
                : ''
            }`}
              </dd>
            </div>
          </dl>
        ) : inProgress ? (
          <dl className="mt-2">
            <div className="flex items-start space-x-3 text-gray-500">
              <dd className="flex justify-center items-center">
                <span className="relative flex h-3 w-3 mr-5">
                  <span className="animate-ping absolute inline-flex h-full w-full rounded-full bg-green-400 opacity-75"></span>
                  <span className="relative inline-flex rounded-full h-3 w-3 bg-green-500"></span>
                </span>
                <span>Class in progress!</span>
              </dd>
            </div>
          </dl>
        ) : null}
        <div className="w-full min-h-0 md:min-h-[250px] px-4 md:px-0 mt-4">
          <UserTable
            name
            job
            country
            userList={bookersList.map((b: BookerData) => {
              return b as UserTableObject;
            })}
          />
        </div>
      </div>

      {!classInPast ? (
        <Menu
          as="div"
          className="absolute right-0 top-6 xl:relative xl:right-auto xl:top-auto"
        >
          <div>
            <Menu.Button className="-m-2 flex items-center rounded-full p-2 text-gray-500 hover:text-gray-600">
              <span className="sr-only">Cancle Booking</span>
              <span className="flex text-blue-immigo hover:text-blue-immigo-lighter">
                +
                <CalendarIcon
                  className="w-6 h-6 mr-2"
                  onClick={() => {
                    atcb_action({
                      name: 'Immigo Class - ' + title,
                      startDate: moment(startMili).local().format('YYYY-MM-DD'),
                      startTime: moment(startMili).local().format('HH:mm'),
                      endDate: moment(startMili)
                        .add(durationMili)
                        .local()
                        .format('YYYY-MM-DD'),
                      endTime: moment(startMili)
                        .add(durationMili)
                        .local()
                        .format('HH:mm'),
                      timeZone: 'currentBrowser',
                      options: ['Apple', 'Google'],
                      location: `${window.location.origin}/class/${classSessionID}`,
                    });
                    trackMyClassesAnalytics(
                      'Clicked add to my calendar on my classes page',
                      {
                        bookingTitle: title,
                        bookingStartMili: startMili,
                      },
                    );
                  }}
                />
              </span>
              <TrashIcon
                onClick={() => {
                  onTrashClick();
                }}
                className="h-6 w-6 text-red-500 hover:text-red-400"
                aria-hidden="true"
              />
            </Menu.Button>
          </div>
        </Menu>
      ) : null}
    </li>
  );
};
