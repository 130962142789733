import axios from 'axios';

const endPoint =
  process.env.REACT_APP_ENV === 'PROD'
    ? 'https://immigo-api.herokuapp.com/destinations'
    : 'http://localhost:8080/destinations'; // Dev env

export async function getDestination(cityTag?: string) {
  const data = await axios.get(`${endPoint}/${cityTag}`);
  return data?.data?.data;
}
