import { ArrowRightIcon } from '@heroicons/react/24/outline';
import { JourneyButtonObject } from '../../types/journey';

export const JourneyButton = (props: JourneyButtonObject) => {
  const { onClick, imageUrl, title, description } = props;
  return (
    <div
      className="bg-black p-4 rounded-lg flex md:flex-row flex-col border-4 border-green-500 bg-center 'bg-center bg-blend-darker bg-gray-400"
      style={{
        backgroundImage: `url(${imageUrl})`,
        backgroundBlendMode: 'multiply',
      }}
      onClick={() => {
        onClick();
      }}
    >
      <div className="rounded-md h-48 w-full md:ml-12 text-left p-6 cursor-pointer hover:border-green-300">
        <h2 className="text-xl font-bold flex items-center dark:text-white">
          <ArrowRightIcon className="w-5 h-5 mr-2" />
          {title}
        </h2>
      </div>
    </div>
  );
};
