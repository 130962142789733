import StackedCardRadio from '../../../components/forms/stackedCardRadio/stackedCardRadio';
import { stringsPricingPage } from '../../../util/localization';

export const MotivationPage = (props: {
  onNextClick: Function;
  setValue: Function;
}) => {
  const { onNextClick, setValue } = props;

  const choices = [
    {
      name: `💼 ${stringsPricingPage.advanceMyCareer}`,
      onClick: async () => {
        setValue('Advance my career');
        onNextClick();
      },
    },
    {
      name: `😊 ${stringsPricingPage.confidenceInSocialSetting}`,
      onClick: async () => {
        setValue('Confidence in social settings');
        onNextClick();
      },
    },
    {
      name: `🧠 ${stringsPricingPage.expandMyKnowledge}`,
      onClick: async () => {
        setValue('Expand my knowledge');
        onNextClick();
      },
    },
    {
      name: `💭 ${stringsPricingPage.other}`,
      onClick: async () => {
        setValue('Other');
        onNextClick();
      },
    },
  ];
  return (
    <div className="md:px-48">
      <div className="text-2xl dark:text-white text-center">
        {stringsPricingPage.welcomeToImmigo}
      </div>
      <div className="mt-6 text-center dark:text-white text-gray-600">
        {stringsPricingPage.tellUsALittleBitAboutYourself}
      </div>
      <div className="mt-6 dark:text-white text-center">
        {stringsPricingPage.whyWorkingOnEnglish}
      </div>
      <div className="mt-6">
        <StackedCardRadio choices={choices} />
      </div>
    </div>
  );
};
